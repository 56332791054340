import { Injectable } from '@angular/core';
import { EnvironmentService, UrlConfig } from "../../environments/environment.service";
import { HttpService } from "../http/http.service";
import { Observable } from "rxjs";
import { HttpHeaders } from '@angular/common/http';
import { SaturnSave } from '../shared/saturn';

@Injectable({
  providedIn: 'root'
})

export class SaturnShippingService {
  urlConfig: UrlConfig;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
  }

  saveShippingAssignment(saturn: SaturnSave[]): Observable<SaturnSave[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this._http.post(this.urlConfig.saveSaturn, JSON.stringify(saturn), httpOptions);
  }
}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { HomeComponent } from './home/home.component';
import { CreateComponent } from './create/create.component';
import { CategoryManagerComponent } from './categorymanager/categorymanager.component';
import { AllocationEZProgramComponent } from './allocation-ezprogram/allocation-ezprogram.component';
import { RecentHistoryComponent } from './recent-history/recent-history.component';
import { ScheduleradminComponent } from './scheduleradmin/scheduleradmin.component';
import { UserutilityComponent } from './userutility/userutility.component';
import { CreatefileComponent } from './createfile/createfile.component';
import { UploadfileComponent } from './uploadfile/uploadfile.component';
import { HistoryfileComponent } from './historyfile/historyfile.component';
import { CreateUserRoleComponent } from "./create-user-role/create-user-role.component";
import { CreateUserAssociationComponent } from './create-user-association/create-user-association.component';
import { SaturnShippingComponent } from './saturn-shipping/saturn-shipping.component';

const routes: Routes = [
  {
    path: 'home', canActivate: [MsalGuard],
    component: HomeComponent
  },
  {
    path: 'create/:type', canActivate: [MsalGuard],
    component: CreateComponent
  },
  {
    path: 'create', canActivate: [MsalGuard],
    component: CreateComponent
  },
  {
    path: 'SaturnShipping', canActivate: [MsalGuard],
    component: SaturnShippingComponent
  },
  {
    path: 'UserAssociation', canActivate: [MsalGuard],
    component: CreateUserAssociationComponent
  },
  {
    path: 'UserRoles', canActivate: [MsalGuard],
    component: CreateUserRoleComponent
  },
  {
    path: 'userUtility',
    component: UserutilityComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'createFile',
    component: CreatefileComponent
  },
  {
    path: 'uploadFile',
    component: UploadfileComponent
  },
  {
    path: 'historyFile',
    component: HistoryfileComponent
  },
  {
    path: 'categoryManager',
    component: CategoryManagerComponent
  },
  {
    path: 'AllocationEZProgram',
    component: AllocationEZProgramComponent
  },
  {
    path: 'scheduler',
    component: ScheduleradminComponent
  },
  {
    path: 'recentHistory',
    component: RecentHistoryComponent
  },
  {
    path: '**',
    redirectTo: 'home'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

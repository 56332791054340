<p-panel>
  <p-table *ngIf="validatedData.length > 0" [value]="validatedData"
           columnResizeMode="fit">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="user"> User
          <p-sortIcon field="user"></p-sortIcon>
        </th>
        <th pSortableColumn="crop">Crop
          <p-sortIcon field="crop"></p-sortIcon>
        </th>
        <th pSortableColumn="station">Station
          <p-sortIcon field="station"></p-sortIcon>
        </th>
        <th pSortableColumn="program">Program
          <p-sortIcon field="program"></p-sortIcon>
        </th>
        <th>Valid</th>
        <th>Notes</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{ row.user }}</td>
        <td>{{ row.crop }}</td>
        <td>{{ row.station }}</td>
        <td>{{ row.program }}</td>
        <td>
          <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': row.valid, 'text-red-500 pi-times-circle': !row.valid }"></i>
        </td>
        <td>{{ row.notes }}</td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <div class="grid">
      <div class="col-6"></div>
      <div class="col-3">
        <button id="btnCancel" type="button"
                class="p-button-warning alignRight"
                style="margin-right: 15px"
                pButton icon="fa fa-close"
                (click)="fileReset()" label="Cancel"></button>
      </div>
      <div class="col-3">
        <button id="btnSubmit" type="button"
                class="p-button-success alignRight"
                pButton icon="fa fa-save"
                [disabled]="!isUploadDataValid"
                (click)="submit()" label="Submit"></button>
      </div>
    </div>
  </p-footer>
</p-panel>

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UserAssociationCsv} from "../../shared/user-association-csv";
import {CommonService} from "../../shared/common.service";
import {NgClass, NgIf} from "@angular/common";
import {TableModule} from "primeng/table";
import {PanelModule} from "primeng/panel";
import {FileUploadModule} from "primeng/fileupload";
import {BadgeModule} from "primeng/badge";
import {ToastModule} from "primeng/toast";
import {CreateUserAssociationService} from "../create-user-association-service";
import {UserAssociationValidated} from "../../shared/user-association-validated";
import {UserAssociationBulkUpload} from "../../shared/user-association-bulk-upload";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-csv-file',
  standalone: true,
  imports: [
    NgIf,
    TableModule,
    PanelModule,
    FileUploadModule,
    BadgeModule,
    NgClass,
    ToastModule
  ],
  templateUrl: './upload-csv-file.component.html',
  styleUrl: './upload-csv-file.component.scss'
})
export class UploadCsvFileComponent implements OnChanges {
  @Input({required: true}) file: File | null;
  @Output() onClose = new EventEmitter();
  @Output() onSubmit: EventEmitter<UserAssociationBulkUpload[]> = new EventEmitter<UserAssociationBulkUpload[]>();
  public csvData: UserAssociationCsv[] = [];
  public validatedData: UserAssociationValidated[] = [];
  public isUploadDataValid: boolean = false;

  constructor(private _commonService: CommonService,
              private _userAssocService: CreateUserAssociationService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.file === null){
      this.csvData = [];
      this.validatedData = [];
      return;
    }

    if (this.file.name.toLowerCase().endsWith(".csv")) {
      this.readCsvFile();
    } else {
      this.readExcelFile();
    }
  }

  readExcelFile(): void {
    if (this.file === null)
      return;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const wb = XLSX.read(data, {type: 'array'});
      const firstSheetName = wb.SheetNames[0];
      const ws = wb.Sheets[firstSheetName];
      let xlsData = XLSX.utils.sheet_to_csv(ws);

      let rows = (<string>xlsData).split(/\r\n|\n/);
      this.processCsvData(rows);

      this.validate();
    }
    reader.onerror = function (error) {
      console.log('An error occurred while reading the file: ', error);
    };

    reader.readAsArrayBuffer(this.file);
  }

  readCsvFile(): void {
    if (this.file === null)
      return;

    let reader = new FileReader();
    reader.readAsText(this.file);
    reader.onload = () => {
      let csvData = reader.result;
      let csvRecordArray: string[] = (<string>csvData).split(/\r\n|\n/);
      let headersRow = this.getHeaderArray(csvRecordArray);
      this.processCsvData(csvRecordArray, headersRow.length);
      this.validate();
    };
    reader.onerror = function (error) {
      console.log('An error occurred while reading the file: ', error);
    };
  }

  private processCsvData(rows: string[], headerLength: number = 4) {
    this.csvData = [];
    for (let i = 1; i < rows.length; i++) {
      let currentRecord = (<string>rows[i]).split(",");
      if (currentRecord.length == headerLength) {
        let exist = this.csvData.some(s =>
          s.user === currentRecord[0].trim() &&
          s.crop === currentRecord[1].trim() &&
          s.station === currentRecord[2].trim() &&
          s.program === currentRecord[3].trim());
        if (!exist) {
          this.csvData.push({
            user: currentRecord[0].trim(),
            crop: currentRecord[1].trim(),
            station: currentRecord[2].trim(),
            program: currentRecord[3].trim(),
            isValid: ''
          });
        }
      }
    }
  }

  fileReset() {
    this.csvData = [];
    this.validatedData = [];
    this.onClose.emit();
  }

  getHeaderArray(csvRecordArray: any): any {
    let headers = (<string>csvRecordArray[0]).split(",");
    let headerArray = [];
    for (let i = 0; i < headers.length; i++) {
      headerArray.push(headers[i].trim());
    }

    return headerArray;
  }

  validate() {
    this._userAssocService.validateBulkUpload(this.csvData).subscribe({
      next: (data: UserAssociationValidated[]) => {
        this.validatedData = data;
        let valid: boolean = true;
        for (let item of this.validatedData) {
          if (!item.valid) {
            valid = false;
            break;
          }
        }
        this.isUploadDataValid = valid;
      }
    });
  }

  submit(): void {
    if (!this.isUploadDataValid)
      return;

    const upload: UserAssociationBulkUpload[] = [];
    this.validatedData.forEach(item => {
      upload.push({
        userId: item.userId,
        cropId: item.cropId,
        stationId: item.stationId,
        programId: item.programId,
        exist: item.exist
      });
    });

    this.csvData = [];
    this.validatedData = [];

    this.onSubmit.emit(upload);
  }
}

import { Option } from "./option";

export class RadUser {
  public Id: string;
  public Email: string;
  public FirstName: string;
  public LastName: string;
  public LoginName: string;
  public DisplayName: string;
  public Roles: string;
  public label: string;
  public value: any;
}

export class RadUserImport {
  public LoginName: string;
  public RolesArray: Option[];
  public Status: number;
  public Notes: string;
  public UserValid: boolean;
  public HasNonExistingRoles: boolean;
}

export class RadUserRolesSave {
  public Id: string;
  public AddedRoles: string[];
  public RemovedRoles: string[];
}

export class RadUserRoles {
  public Id: string;
  public LoginName: string;
  public RolesArray: Option[];
}

export class RadUserImportResults {
  public ExistingUserRoles: RadUserRoles[];
  public NewUserRoles: RadUserRoles[];
  public NewRoles: Option[];
}

export const ROLE_DELIMIETER = "|";
import { Injectable } from '@angular/core';
import { EnvironmentService, UrlConfig } from "../../environments/environment.service";
import { HttpService } from "../http/http.service";
import { Observable } from "rxjs";
import { RadUser, RadUserRolesSave } from '../shared/radUser';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateUserRoleService {
  urlConfig: UrlConfig;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
  }

  loadUsers(): Observable<RadUser[]> {
    return this._http.get(this.urlConfig.users);
  }

  saveUserRoles(userRoles: RadUserRolesSave[]): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this._http.post(this.urlConfig.saveUserRoles, JSON.stringify(userRoles), httpOptions);
  }

  syncUserRolesWithMsGraph(): Observable<RadUserRolesSave[]>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    return this._http.post(this.urlConfig.syncUserRoles, '', httpOptions);
  }
}
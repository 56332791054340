import {EnvironmentService, UrlConfig} from "src/environments/environment.service";
import {HttpService} from "../http/http.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {UserExistingAndNewAssociations} from "../shared/user-existing-and-new-associations";
import {AppModel} from "../shared/appModel";
import {UserAppProgramAssoc} from "../shared/user-app-program-assoc";
import {UserAppStationAssoc} from "../shared/user-app-station-assoc";
import {HttpHeaders} from "@angular/common/http";
import {UserAssociationCsv} from "../shared/user-association-csv";

@Injectable({
  providedIn: 'root'
})

export class CreateUserAssociationService {
  urlConfig: UrlConfig;

  constructor(private _http: HttpService, private environment: EnvironmentService) {
    this.urlConfig = environment.getEnvironment();
  }

  loadUsers(): Observable<any> {
    return this._http.get(this.urlConfig.users);
  }

  loadAssociations(userIds: string[], cropIds: string[], programIds: string[], stationIds: string[]): Observable<UserExistingAndNewAssociations> {
    let url = `${this.urlConfig.getUserAssociations}`;
    let first = true;

    if (userIds.length > 0) {
      url += first ? `?userIds=${userIds.join(',')}` : `&userIds=${userIds.join(',')}`;
      first = false;
    }

    if (cropIds.length > 0) {
      url += first ? `?cropIds=${cropIds.join(',')}` : `&cropIds=${cropIds.join(',')}`;
      first = false;
    }

    if (programIds.length > 0) {
      url += first ? `?programIds=${programIds.join(',')}` : `&programIds=${programIds.join(',')}`;
      first = false;
    }

    if (stationIds.length > 0) {
      url += first ? `?stationIds=${stationIds.join(',')}` : `&stationIds=${stationIds.join(',')}`;
    }

    return this._http.get(url);
  }

  loadAssociationsForCopy(sourceUserId: string, targetUsersIds: string[]): Observable<UserExistingAndNewAssociations> {
    let url = `${this.urlConfig.getAssociationsForCopy}?sourceUserId=${sourceUserId}&targetUsersIds=${targetUsersIds.join(',')}`;

    return this._http.get(url);
  }

  getApps(): Observable<AppModel[]> {
    return this._http.get(this.urlConfig.getApps);
  }

  saveStationAssociations(assoc: UserAppStationAssoc[]): Observable<UserAppStationAssoc[]> {
    const data = JSON.stringify(assoc);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(this.urlConfig.saveUserAppStationAssoc, data, httpOptions);
  }

  saveProgramAssociations(assoc: UserAppProgramAssoc[]): Observable<UserAppProgramAssoc[]> {
    const data = JSON.stringify(assoc);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(this.urlConfig.saveUserAppProgramAssoc, data, httpOptions);
  }

  removeStationAssociations(assoc: UserAppStationAssoc[]): Observable<UserAppStationAssoc[]> {
    const data = JSON.stringify(assoc);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(this.urlConfig.removeUserAppStationAssoc, data, httpOptions);
  }

  removeProgramAssociations(assoc: UserAppProgramAssoc[]): Observable<UserAppProgramAssoc[]> {
    const data = JSON.stringify(assoc);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(this.urlConfig.removeUserAppProgramAssoc, data, httpOptions);
  }

  validateBulkUpload(data: UserAssociationCsv[]) {
    const body = JSON.stringify(data);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.post(this.urlConfig.userAssociationsValidateBulkUpload, body, httpOptions);
  }
}

import { Component, OnInit } from '@angular/core';
import { PanelModule } from "primeng/panel";
import { ButtonDirective } from "primeng/button";
import { FormBuilder, FormControl, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { SharedService } from '../services/shared.service';
import { UserClaim } from '../shared/userClaim';
import { Constants, CropModel, Station } from '../shared';
import { ProgramService } from '../program/program.service';
import { CommonService } from '../shared/common.service';
import { SaturnShippingService } from './saturn-shipping.service';
import { SaturnSave } from '../shared/saturn';

@Component({
  selector: 'app-saturn-shipping',
  standalone: true,
  imports: [
    PanelModule,
    ButtonDirective,
    ReactiveFormsModule,
    DropdownModule
  ],
  templateUrl: './saturn-shipping.component.html',
  styleUrl: './saturn-shipping.component.scss'
})

export class SaturnShippingComponent implements OnInit{
  public isROVAdmin = false;
  public loading = false;
  public saving = false;
  public canSubmit = false;

  public crops: CropModel[] = [];
  public filteredCrops: CropModel[] = [];

  public stations: Station[] = [];
  public filteredStations: Station[] = [];

  public saturnShippingForm = this.fb.nonNullable.group({
    saturnID: new FormControl(),
    selectedCrop: new FormControl(),
    selectedStation: new FormControl(),
  });

  constructor(private fb: FormBuilder, private _sharedService: SharedService,
    private _commonService: CommonService, private _programService: ProgramService,
    private _shippingService: SaturnShippingService) {
  }

  ngOnInit(): void {
    this.getLoginInfo();
    this.loadFormData();
  }

  reset() {
    this.saturnShippingForm.controls.saturnID.setValue(undefined);
    
    this.saturnShippingForm.controls.selectedCrop.setValue(undefined);
    this.filteredCrops = this.crops;

    this.saturnShippingForm.controls.selectedStation.setValue(undefined);
    this.filteredStations = this.stations;

    this.verifySelections();
  }

  getLoginInfo() {
    let localRoles = this._sharedService.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.isROVAdmin = userClaim.isROVAdmin;
    }
  }

  loadFormData() {
    this.loading = true;

    this._programService.bindProgramDropdownLists().subscribe({
      next: data => {
        if (data.Crops && data.Crops.length > 0) {
          this.crops = data.Crops;
          this.crops.forEach(item => {
            item.value = item.CropID;
            item.label = item.CropDescription;
          });

          this.crops.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
          this.filteredCrops = this.crops;
        }

        if (data.Stations && data.Stations.length > 0) {
          this.stations = data.Stations;
          this.stations.forEach(item => {
            item.value = item.StationID;
            item.label = item.StationCode + ' - ' + item.StationName;
          });

          this.stations.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
          this.filteredStations = this.stations;
        }
      },
      error: error => {
        if (error === '') {
          this._commonService.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
        else {
          this._commonService.DisplayErrorMessage('Error', error);
        }

        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  onCropChanged(crop: any) {
    let station = this.saturnShippingForm.controls.selectedStation.value;

    if(!crop || !crop.value) {
      if(!station)
        this.filteredCrops = this.crops;

      this.filteredStations = this.stations;
    }
    else {
      let found = this.crops.find(c => c.CropID === crop.value.CropID);
      if(!found)
        return;

      this.filteredStations = this.stations.filter(s => s.StationCrops.some(sc => sc.CropID === found.CropID));
      if(station) {
        let found = this.filteredStations.find(s => s.StationID === station.StationID);
        if(!found) {
          this.saturnShippingForm.controls.selectedStation.setValue(undefined);
        }
      }
    }

    this.verifySelections();
  }

  onStationChanged(station: any) {
    let crop = this.saturnShippingForm.controls.selectedCrop.value;

    if(!station || !station.value) {
      if(!crop)
        this.filteredStations = this.stations;

      this.filteredCrops = this.crops;
    }
    else {
      let found = this.stations.find(s => s.StationID === station.value.StationID);
      if(!found)
        return;

      this.filteredCrops = [];
      found.StationCrops.forEach(sc => {
        let crop = this.crops.find(c => c.CropID === sc.CropID);
        if(crop) {
          this.filteredCrops.push(crop);
        }
      });

      if(crop) {
        let found = this.filteredCrops.find(s => s.CropID === crop.CropID);
        if(!found) {
          this.saturnShippingForm.controls.selectedCrop.setValue(undefined);
        }
      }
    }

    this.verifySelections();
  }

  submit() {
    let id: number = this.saturnShippingForm.controls.saturnID.value;
    let crop: CropModel = this.saturnShippingForm.controls.selectedCrop.value;
    let station: Station = this.saturnShippingForm.controls.selectedStation.value;

    const toSave: SaturnSave[] = [];
    toSave.push( { 
      id: id, 
      cropId: crop.CropID, 
      stationId: station.StationID, 
      associated: true
    });

    this.saving = true;

    this._shippingService.saveShippingAssignment(toSave).subscribe({
      next: saved => {
        if (saved) {
          if (saved.length === 1 && saved[0].id === id) {
            this._commonService.DisplaySuccessMessage('Success', 'SaturnID ' + id + ' saved');
          }
          else {
            this._commonService.DisplayInfoMessage('Exists', 'Association already exists');
          }
        }
        else
          this._commonService.DisplayErrorMessage('Error', 'There was a problem saving this Saturn Shipping assignment');
      },
      error: error => {
        if (error === '') {
          this._commonService.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
        else {
          this._commonService.DisplayErrorMessage('Error', error);
        }

        this.saving = false;
      },
      complete: () => {
        this.saving = false;
      }
    })
  }

  verifySelections() {
    let id = this.saturnShippingForm.controls.saturnID.value;
    let crop = this.saturnShippingForm.controls.selectedCrop.value;
    let station = this.saturnShippingForm.controls.selectedStation.value;

    this.canSubmit = id && crop && station;
  }
}
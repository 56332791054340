<div class="container-fluid" [hidden]="isROVAdmin">
    <div class="col-sm-3">
    </div>
  
    <div class="col-sm-6">
      <h3>
          <span>
            You don't have access to the
            <b>Saturn Shipping</b> screen.
            <br/>
            <a id="linkUserUtilityHomeScreen" href="/">Click Here</a> to go to Home Screen.
          </span>
      </h3>
    </div>
  
    <div class="co-sm-3">
    </div>
  </div>
  
<form id="frmSaturnShipping" [formGroup]="saturnShippingForm">
    <p-panel>
        <p-header id="pnlHdrShipping">
            <span>
              Shipping
            </span>
        </p-header>

        <div class="grid">
            <div class="controlLabel col-3">
                <label id="lblSaturnID">Saturn ID</label>
            </div>
            <div class="col-7">
                <input id="txtSaturnID" formControlName="saturnID" autofocus type="number"
                        (change)="verifySelections()" (keyup)="verifySelections()"/>
            </div>

            <div class="controlLabel col-3">
                <label id="lblCrop">Crop</label>
            </div>
            <div class="col-2">
                    <span class="p-fluid">
                        <p-dropdown id="ddCrops" formControlName="selectedCrop"
                                (onChange)="onCropChanged($event)"
                                [options]="filteredCrops" optionLabel="label"
                                [placeholder]="'Select a Crop'"
                                [filter]="true" [resetFilterOnHide]="true"
                                [showClear]="true"
                                [loading]="loading"></p-dropdown>
                    </span>
            </div>
            <div class="col-5"></div>

            <div class="controlLabel col-3">
                <label id="lblStation">Station</label>
            </div>
            <div class="col-2">
                    <span class="p-fluid">
                        <p-dropdown id="ddStations" formControlName="selectedStation"
                                (onChange)="onStationChanged($event)"
                                [options]="filteredStations" optionLabel="label"
                                [placeholder]="'Select a Station'"
                                [filter]="true" [resetFilterOnHide]="true"
                                [showClear]="true"
                                [loading]="loading"></p-dropdown>
                    </span>
            </div>
            <div class="col-5"></div>
        </div>

        <p-footer>
            <div class="grid">
                <div class="col-8"></div>
                <div class="col-2">
                    <button id="btnReset" type="button"
                            class="p-button-warning alignRight"
                            pButton icon="fa fa-close"
                            (click)="reset()" label="Reset"></button>
                </div>
                <div class="col-2">
                    <button id="btnSubmit" type="button"
                            class="p-button-success alignRight"
                            pButton icon="fa fa-save"
                            (click)="submit()"
                            [disabled]="!canSubmit"
                            [loading]="saving"
                            label="Submit"></button>
                </div>
            </div>
        </p-footer>
    </p-panel>
</form>
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "./environments/environment";

import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

import { AppModule } from "./app/app.module";
import { ApplicationSettings, APPLICATION_SETTINGS } from "./app/application-settings.service";

const msalLoggerCallback = (logLevel: LogLevel, message: string) => console.log(`[MSAL][${LogLevel[logLevel]}]: ${message}`);

const fetchAssets = () => {
  return Promise.all([
    fetch('/assets/application-settings.json')
  ]).then(results => {

    return Promise.all(results.map(result => result.json()));
  });
};

fetchAssets()
  .then(result => {

    const appSettings: ApplicationSettings = result[0];

    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      {
        provide: MSAL_INSTANCE, useValue: new PublicClientApplication({
          auth: appSettings.msal.config.auth,
          cache: appSettings.msal.config.cache,
          system: {
            loggerOptions: {
              loggerCallback: msalLoggerCallback,
              logLevel: LogLevel.Warning,
              piiLoggingEnabled: false
            }
          }
        })
      },
      {
        provide: MSAL_GUARD_CONFIG, useValue: { ...appSettings.msal.guard }
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: { protectedResourceMap: new Map(appSettings.msal.interceptor.protectedResourceMap) }
      },
      {
        provide: APPLICATION_SETTINGS, useValue: { ...appSettings }
      }
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });


import {Component, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {Constants, RequestStatus, LookupType, GAAData, Station} from "../shared";
import {ConfirmationService, SelectItem, MessageService} from 'primeng/api';
import {AppService} from '../app.service';
import {StationService} from './station.service';
import {CommonService} from '../shared/common.service';
import {StationResultModel} from '../shared';
import {ApprovalComments} from '../shared';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {LERequestedData} from '../shared/lERequstedData';
import {ExperimentProjectCodeResult} from '../shared';
import {LocationProjectCodeResult} from '../shared';
import {ProjectLEResult} from '../shared';
import {SSLLEResult} from '../shared';
import {StationLEResult} from '../shared';
import {BaseStationComponent} from '../basecomponents/basestation.component';
import {BaseService} from '../basecomponents/base.service';
import {StationCrops} from '../shared/stationCrops';
import {SharedService} from '../services/shared.service';
import {Saturn} from "../shared/saturn";

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.css']
})

export class StationComponent extends BaseStationComponent {
  public _approvalComments: ApprovalComments = new ApprovalComments();
  StationID: string;
  status: SelectItem[] = [];
  CropName: string;
  cols: any[];
  selectedItems: any[];
  dialogVisible: boolean;
  technologyTiers: LookupType[];
  displayComments: boolean;

  stationGridHeight = 0;
  selectedStatus: string;
  sourceIdentifier: string;

  btnApprove = false;
  expandedItems: any[] = [];

  public disableCommentButton = false;

  public stationAllData: StationResultModel[] = [];
  public filteredData: StationResultModel[] = [];

  public approvalCommentData: any[] = [];
  public distinctCodes: SelectItem[];
  public distRegions: SelectItem[];

  public saveLabelText = 'Save';
  public saveIcon = 'fa fa-save';
  public saveIconClass = 'p-button-success';
  public resizeIcon = 'fa fa-expand';

  public filteredStationCode: string;
  public filteredStationName: string;
  public filteredCropNames: string;
  public filteredRegionNames: string;
  public filteredState: string;
  public filteredCountry: string;
  public filteredUserResponsibilityStationAssocNames: string;
  public filteredStationRequestStatus: string;

  public consolidationCrops: StationCrops[] = [];
  public selectedConsolidationCrops: StationCrops[] = [];
  public displayLEIDWindow = false;
  public existingLEIDString = '';
  public leIDs: number[] = [];
  public projectLEResult: ProjectLEResult[] = [];
  public stationLEResult: StationLEResult[] = [];
  public sSLLEResult: SSLLEResult[] = [];
  public locationProjectCodeResult: LocationProjectCodeResult[] = [];
  public experimentProjectCodeResult: ExperimentProjectCodeResult[] = [];
  public IDCreated = false;
  public gaaData: GAAData = new GAAData();
  public height = 580;
  public width = 1200;
  public positionLeft = '50px';
  public positionTop = '40px';
  public panelHeight = '350px';
  public top = 0;
  public left = 0;

  public stationSaturns: Saturn[] = [];

  expandedRows: { [id: string]: boolean } = {};
  first: number = 0;
  @Input()
  public selectedTabIndex = 0;

  @Input()
  public selectedRequestID: string;

  @Input()
  public cleanCache: boolean;

  @Output()
  public cleanUpCache: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _confirmation: ConfirmationService, private route: ActivatedRoute,
              private _appService: AppService, public override _stationService: StationService,
              private _commonService: CommonService, public override _fb: FormBuilder,
              public override _baseService: BaseService, _messageService: MessageService,
              sharedservice: SharedService) {
    super(_fb, _baseService, _stationService, _messageService, sharedservice);
  }

  override ngOnInit() {
    this.parentComponent = 'R&D Center';
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (this.selectedTabIndex === 1) {
      super.ngOnChanges(changes);
      this.buildForm(false);

      if ((this.cleanCache || (this.stationAllData == null || this.stationAllData.length === 0))
        && (changes['selectedTabIndex'] && changes['selectedTabIndex'].currentValue === 1)) {
        this.route.queryParams.subscribe(p => {
          const stationID: string = p['StationID'];
          this.selectedRequestID = stationID ?? '';
        })

        this.cols = [
          {field: 'StationID', header: 'R&D Center ID', hidden: 'true'},
          {field: 'StationCode', header: 'R&D Center Code'},
          {field: 'StationName', header: 'R&D Center Name'},
          {field: 'CropNames', header: 'Crop Names'},
          {field: 'RegionName', header: 'RCZ', title: 'Research Commercial Zone'},
          {field: 'State', header: 'State'},
          {field: 'Country', header: 'Country'},
          {field: 'UserResponsibilityStationAssocNames', header: 'R&D Center Operations Lead'},
          {field: 'RequestStatus', header: 'Status'}
        ];

        this.getLookupTypes();
        this.getStationsData();
      }
    }
  }

  getStationsData() {
    if (this.stations.length === 0) {
      this.loading = true;
      this.getDropdownLists();
      this._baseService.getStationResult().subscribe({
          next: (response) => {
            this.stations = response;
            this.stations.forEach(element => {
              element.Saturns.forEach(s => {
                s.value = s.id;
                s.label = `${s.station.StationCode} - ${s.crop.CropDescription} - ${s.id}`
              });
              element.SaturnLabel = element.Saturns.filter(f=>f.associated).map(m => m.label).join(", ");
            });
            this.loadStationData();
            this.loading = false;

            if (response != null && this.selectedRequestID !== '') {
              const selectedItem = response.find(element => element.StationID.toString());
              this.first = 0;
              if (!selectedItem) {
                this.DisplayInfoMessage('Info', `The R&D Center
              requested in the URL either doesn't exist or is no more in Pending State.`);
              }
              else {
                let index = response.indexOf(selectedItem);
                const pageNumber = Math.ceil(++index / 10)
                this.first = (pageNumber - 1) * 10;
                this.expandedRows[this.selectedRequestID.toLowerCase()] = true;
                this.editStation(selectedItem);
              }
            }
          },
          error: error => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
            this.loading = false;
          },
        complete: () => {
              this.loading = false;
            }
      });
    } else {
      this.loadStationData();
    }
  }

  loadStationData() {
    this.loading = true;
    this.filteredData = this.stationAllData = this.stations;
    this.stationCodes = [];
    this.stationAllData.forEach(stn => {
        this.stationCodes.push({
          StationID: stn.StationID,
          StationCode: stn.StationCode,
          StationName: stn.StationName,
          StationCrops: [],
          CityId: 0,
          City: '',
          CountyId: 0,
          County: '',
          StateId: 0,
          State: '',
          CountryId: 0,
          Country: '',
          CountryCode: '',
          RegionID: '',
          RequestStatus: stn.RequestStatus,
          label: stn.StationName + ' - ' + stn.StationCode,
          value: stn.StationID,
          GeographicLocale: stn.GeographicLocale,
          Saturns: stn.Saturns,
        });

        if (this.stationAllData != null && this.selectedRequestID !== '') {
          const index = this.stationAllData.findIndex(element => element.StationID.toString()
            === this.selectedRequestID.toString());
          if (!(index === undefined || index == null || index === -1)) {
            this.expandRow(index);
          } else {
            this.DisplayInfoMessage('Info', `The Station
              requested in the URL either doesn't exist or is no more in Pending State.`);
          }
        }
        this.distinctStationCodesDropdown();
        this.loading = false;
      }
    );
  }

  syncGAA() {
    this._stationService.syncGAA("Station")
      .subscribe({
        next: data => {
        },
        error: error => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
      });
  }

  distinctStationCodesDropdown() {
    const items = this.stationCodes;
    let flags: { [code: string]: boolean } = {};
    const l = items.length;
    this.distinctCodes = [];
    for (let i = 0; i < items.length; i++) {
      if (!flags[items[i].StationCode]) {
        flags[items[i].StationCode] = true;
        this.distinctCodes.push({
          label: items[i].StationCode,
          value: items[i].StationCode
        });
      }
    }
    if (this.distinctCodes) {
      this.sortedCodes = this.distinctCodes.sort((a, b) => {
        if (a.value.toLowerCase() < b.value.toLowerCase()) {
          return -1;
        } else if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return 0;
        };
      });
    }
    this.distinctCodes = this.sortedCodes.slice();

    let allRegions: string[] = this.stations.map(i => i.Region.Name);
    this.distRegions = this._commonService.GetDistinctSortedItems(allRegions);
  }

  expandRow(item: number) {
    let exists = false;
    this.expandedItems.forEach(x => {
      if (x.StationID === this.stationAllData[item].StationID) {
        exists = true;
      }
    });

    if (exists) {
      this.expandedItems.pop();
    } else {
      this.expandedItems.push(this.stationAllData[item]);

      if (this.isROVAdmin
        || (this.stationAllData[item].CreatedBy.Email.toLocaleLowerCase() === this.userName.toLocaleLowerCase())) {
        try {
          setTimeout(function () {
            document.getElementById('dtStations')
              ?.querySelector('p-rowexpansionloader')?.scrollIntoView();
          }, 100);
        } catch (err: any) { console.log(Constants.SCROLLERROR + err.toString()); }

        this.editStation(this.stationAllData[item]);
      }
    }
  }

  editStation(station: StationResultModel) {
    if (this.crops.length === 0) {
      this._baseService.getDropdownLists().subscribe(data => {
          this.fillDropDownList(data);
          this.stationEdit(station);
        }
      );
    } else {
      this.stationEdit(station);
    }
  }

  stationEdit(station: StationResultModel) {
    this.consolidationCrops = [];
    this.IDCreated = false;

    if (this.isROVAdmin) {
      this.stationFormGroup.controls['RequestStatus'].enable();
    } else {
      this.stationFormGroup.controls['RequestStatus'].disable();
    }
    this.stationSaturns = station.Saturns;
    this.stationSaturns.forEach(s => {
      s.value = s.id;
      s.label = `${s.station.StationCode} - ${s.crop.CropDescription} - ${s.id}`
    })
    this.selectedSaturns = this.stationSaturns.filter(s => s.associated);

    this.stationData = Object.assign({}, station);
    this.stationForm = Object.assign({}, station);
    this.stationForm.SaturnLabel = this.stationSaturns.map(s => s.label).join(", ");

    this.stationFormGroup.controls['saturn'].setValue(this.selectedSaturns)

    setTimeout(() => {
      this.buildForm(false);
    }, 10);

    this.dialogVisible = true;
    this.selectedCrops = [];
    this.regionCodes = [];
    this.selectedConsolidationCropIds = [];

    const region = this.regions.find(r => r.Id == this.stationData.RegionID);
    if (region)
      this.regionCodes.push(region);

    this.regions.forEach(reg => {
      if (!this.regionCodes.some(r => r.Code == reg.Code)) {
        this.regionCodes.push(reg);
      }
    });

    this.crops.forEach(element => {
      station.StationCrops.filter(ua => ua.CropID === element.CropID).forEach(e2 => {
        this.selectedCrops.push(e2.CropID);
        this.consolidationCrops.push(element);
        if (e2.ConsolidationFlag == true) {
          this.selectedConsolidationCropIds.push(e2.CropID);
          this.selectedConsolidationCrops.push(element);
        }
      });
    });

    this.sortRegions();
    this.stationOperationLead = [];
    if (station.UserResponsibilityStationAssoc !== null) {
      station.UserResponsibilityStationAssoc.forEach(pr => {
        this.stationOperationLead.push({label: pr.Person.DisplayName, value: pr.Person.DisplayName});
      });
    }

    this.shiptoStationContact = [];
    if (station.UserResponsibilityShiptoStationAssoc !== null) {
      station.UserResponsibilityShiptoStationAssoc.forEach(pr => {
        this.shiptoStationContact.push({label: pr.Person.DisplayName, value: pr.Person.DisplayName});
      });
    }

    this.stationSafetyContact = [];
    if (station.UserResponsibilityStationSafetyContactAssoc !== null) {
      station.UserResponsibilityStationSafetyContactAssoc.forEach(pr => {
        this.stationSafetyContact.push({label: pr.Person.DisplayName, value: pr.Person.DisplayName});
      });
    }

    if (!this.stationForm.StationTypeID || !this.stationTypes.find(s => s.Id.toString()
      === this.stationForm.StationTypeID.toString())) {
      this.stationForm.StationTypeID = '';
    }

    if (!this.stationForm.RegionID || !this.regionCodes.find(s => s.Id.toString()
      === this.stationForm.RegionID.toString())) {
      this.stationForm.RegionID = '';
    }

    this.status = [];

    if (this.isROVAdmin) {
      this.status.push({label: RequestStatus.ACTIVE, value: RequestStatus.ACTIVE});
      this.status.push({label: RequestStatus.DECLINED, value: RequestStatus.DECLINED});
    }
    this.status.push({label: RequestStatus.PENDING, value: RequestStatus.PENDING});

    if (station.RequestStatus !== RequestStatus.PENDING) {
      let index = this.status.findIndex(s => s.value === RequestStatus.DECLINED);

      if (index !== -1) {
        this.status.splice(index, 1);
      }

      index = this.status.findIndex(s => s.value === RequestStatus.PENDING);

      if (index !== -1) {
        this.status.splice(index, 1);
      }
      this.status.push({label: RequestStatus.RETIRED, value: RequestStatus.RETIRED});
      this.status.push({label: RequestStatus.TRANSITIONING, value: RequestStatus.TRANSITIONING});
    }

    this.selectedStatus = this.status.find(s => s.label === station.RequestStatus)?.value;
    this.stationForm.RequestStatus = this.selectedStatus;
    this.stationForm.StationID = station.StationID;
    this.stationForm.BaseStationID = station.BaseStationID;

    this.stationForm.ApprovalComments = [];
    this.stationFormGroup.controls['Comment'].setValue('');

    this.statusChange(this.stationForm.RequestStatus, false);

    if (this.isROVAdmin) {
      this.getLEData(this.stationForm.StationCode);
    }
  }

  getLEData(code: string) {
    if (code) {

      let re = /\&/gi;
      const queryString = `code=${code}&type=${this.parentComponent.replace(re, "%26")}`;

      this.projectLEResult = [];
      this.stationLEResult = [];
      this.sSLLEResult = [];
      this.locationProjectCodeResult = [];
      this.experimentProjectCodeResult = [];
      this.leIDs = [];

      this._commonService.getLEAndAOIReportData(queryString)
        .subscribe({
          next: Result => {
            if (Result) {
              this.projectLEResult = Result.ProjectLE;
              this.projectLEResult = this.projectLEResult.slice();

              if (this.projectLEResult && this.projectLEResult.length > 0) {
                this.projectLEResult.forEach(s => {
                  this.leIDs.push(s.LEId);
                });
              }

              this.stationLEResult = Result.StationLE;
              this.stationLEResult = this.stationLEResult.slice();

              if (this.stationLEResult && this.stationLEResult.length > 0) {
                this.stationLEResult.forEach(s => {
                  this.leIDs.push(s.LEId);
                });
              }

              this.sSLLEResult = Result.SSLLE;
              this.sSLLEResult = this.sSLLEResult.slice();

              this.locationProjectCodeResult = Result.LocationProjectCode;
              this.locationProjectCodeResult = this.locationProjectCodeResult.slice();

              this.experimentProjectCodeResult = Result.ExperimentProjectCode;
              this.experimentProjectCodeResult = this.experimentProjectCodeResult.slice();
            }
          },
          error: (error: any) => {
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
        });
    }
  }

  public getLookupTypes() {
    this._commonService.getLookupTypes(Constants.LOOKUPTYPETECHNOLOGYTIERTYPE, true)
      .subscribe({
        next: (response) => {
          this.technologyTiers = response;

          this.technologyTiers.forEach(element => {
            element.value = element.Id;
            element.label = element.Name;
          });
        },
        error: (error) => {
        }
      });

  }

  changeCrops(event: any) {
    this.stationForm.StationCrops = [];
    this.consolidationCrops = [];
    event.value.forEach((selectedItem: string) => {
      const cropData = this.crops.find(s => s.CropID === selectedItem)
      this.stationForm.StationCrops.push({
        CropID: cropData!.CropID,
        CropName: cropData!.CropName,
        CropDescription: cropData!.CropDescription,
        StationID: cropData!.StationID,
        StationCropID: cropData!.StationCropID,
        ConsolidationFlag: null
      });
      this.consolidationCrops.push(cropData!);
    });

    this.consolidationCrops = this.consolidationCrops.sort((a, b) => a.CropName.localeCompare(b.CropName));
    this.selectedConsolidationCrops.forEach((item, index) => {
      const cropExists = this.consolidationCrops.some(s => s.CropID === item.CropID);
      if (!cropExists) {
        this.selectedConsolidationCrops.splice(index, 1);
        this.selectedConsolidationCropIds = this.selectedConsolidationCropIds.filter(x => x != item.CropID);
      }
    });

    this.stationForm.StationCrops.forEach((item, index) => {
      if (this.selectedConsolidationCrops.some(s => s.CropID === item.CropID)) {
        this.stationForm.StationCrops[index].ConsolidationFlag = true;
      }
      else{
        this.stationForm.StationCrops[index].ConsolidationFlag = false;
      }
    });
  }

  changeConsolidationCrops(event: any) {
    this.selectedConsolidationCropIds = [];
    this.selectedConsolidationCrops = [];
    this.consolidationCrops.forEach(Ccrops => {
      let exists = event.value.includes(Ccrops.CropID);
      let index = this.stationForm.StationCrops.findIndex(x => x.CropID === Ccrops.CropID);
      if (exists) {
        this.selectedConsolidationCropIds.push(Ccrops.CropID);
        this.stationForm.StationCrops[index].ConsolidationFlag = true;
        this.selectedConsolidationCrops.push(this.stationForm.StationCrops[index]);
      }
      else{
        this.stationForm.StationCrops[index].ConsolidationFlag = null;
      }
    });
  }

  sortRegions() {
    if (this.regionCodes) {
      this.sortedCodes = this.regionCodes.sort((a, b) => {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
          return -1;
        } else if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  onCloseEditStation(event: any, frm: any) {
    setTimeout(() => {
      this.close(frm);
    }, 100);
  }

  close(frm: any) {
    if (frm.dirty) {
      this._confirmation.confirm({
        message: Constants.PENDINGCHANGES,
        accept: () => {
          this.dialogVisible = false;
          this.existingPeople = [];
          this.existingPeopleString = '';
          this.buildForm(false);
        }
      });
    } else {
      this.dialogVisible = false;
      this.existingPeople = [];
      this.existingPeopleString = '';
    }
  }

  filterStationDataTable(event: any) {
    this.filteredData = event.filteredValue;
  }

  exportCSVForStations() {
    if (this.filteredData == null || this.filteredData.length === 0) {
      this.DisplayErrorMessage('Error', Constants.NOTHINGTOEXPORT);
      return;
    }

    const exportData = this.filteredData;
    this._stationService.exportCSVForStations(JSON.stringify(exportData))
      .subscribe({
        next: data => {
          const blob = new Blob([data], {type: 'application/ms-excel'});
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = 'RDCenterData.xlsx';
          document.body.appendChild(link);
          link.click();

          setTimeout(function () {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 100);
        },
        error: (error: any) => {
          this.DisplayErrorMessage('Error', Constants.NOSERVICE);
        }
      });
  }

  setSaveButtonStyle(status: string) {
    if (status === RequestStatus.PENDING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-primary';
      this.saveLabelText = 'Save';
    } else if (status === RequestStatus.ACTIVE) {
      this.saveIcon = 'fa fa-check';
      this.saveIconClass = 'p-button-secondary';
      this.saveLabelText = 'Mark as Active';
    } else if (status === RequestStatus.DECLINED) {
      this.saveIcon = 'fa fa-trash';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Declined';
    } else if (status === RequestStatus.RETIRED) {
      this.saveIcon = 'fa fa-ban';
      this.saveIconClass = 'p-button-danger';
      this.saveLabelText = 'Mark as Retired';
    } else if (status === RequestStatus.TRANSITIONING) {
      this.saveIcon = 'fa fa-save';
      this.saveIconClass = 'p-button-info';
      this.saveLabelText = 'Mark as Transitioning';
    }

    this.saveIconClass = this.saveIconClass + ' p-button p-widget p-state-default p-corner-all p-button-text-icon-left';
  }

  statusChange(status: string, isChange: boolean) {
    this.setSaveButtonStyle(status);
    if (status === this.selectedStatus) {
      this.saveLabelText = 'Save';
    }
    if (this.stationForm.RequestStatus === RequestStatus.RETIRED || this.stationForm.RequestStatus === RequestStatus.TRANSITIONING) {
      this.stationFormGroup = this._fb.group({
        'StationName': [this.stationForm.StationName, Validators.required],
        'UserResponsibilityStationSafetyContactAssocNames': [this.stationForm.UserResponsibilityStationSafetyContactAssocNames],
        'UserResponsibilityStationAssocNames': [this.stationForm.UserResponsibilityStationAssocNames],
        'UserResponsibilityShiptoStationAssocNames': [this.stationForm.UserResponsibilityShiptoStationAssocNames],
        'crop': [this.selectedCrops],
        'R3SiteCode': [this.stationForm.R3SiteCode],
        'R3ShipTo': [this.stationForm.R3ShipTo],
        'R3SoldTo': [this.stationForm.R3SoldTo],
        'R3DeliveryPlant': [this.stationForm.R3DeliveryPlant],
        'description': [this.stationForm.Description],
        'RequestStatus': [this.stationForm.RequestStatus],
        'stationCode': [this.stationForm.StationCode],
        'PRISMLEID': [this.stationForm.PRISMLEID, Validators.required],
        'Address': [this.stationForm.Address],
        'CityId': [this.stationForm.CityId],
        'City': [this.stationForm.City],
        'CountyId': [this.stationForm.CountyId],
        'County': [this.stationForm.County],
        'StateId': [this.stationForm.StateId],
        'State': [{value: this.stationForm.State}],
        'CountryId': [this.stationForm.CountryId],
        'Country': [this.stationForm.Country],
        'Longitude': [this.stationForm.Longitude],
        'Latitude': [this.stationForm.Latitude],
        'Comment': [this.stationForm.ApprovalComments],
        'StationTypeID': [this.stationForm.StationTypeID],
        'RegionID': [this.stationForm.RegionID],
        'CorporateITCode': [this.stationForm.CorporateITCode],
        'TechnologyTierTypeId': [this.stationForm.TechnologyTierTypeId],
        'consolidationCrop': [this.selectedConsolidationCropIds],
        'Saturns': [this.selectedSaturns],
      });
    } else {
      this.buildForm(false);
    }
    if (isChange)
      this.stationFormGroup.markAsDirty();
  }

  saveStation(frm: any, status: string) {
    if (status === RequestStatus.DECLINED) {
      this._confirmation.confirm({
        message: Constants.CONFIRMDECLINE,
        accept: () => {
          this.save(frm, status);
        }
      });
    } else {
      this.save(frm, status);
    }
  }

  save(frm: any, status: string) {
    this.stationData = this.stationForm;

    this.stationSaturns.forEach(f=>{
      f.associated = this.selectedSaturns.some(s => s.id === f.id);
    });

    this.stationData.Saturns = this.stationSaturns;

    let saturnNames = this.stationData.Saturns.filter(f=>f.associated).map(m=>m.label).join(", ");

    let cropNames = '';
    this.stationData.StationCrops.forEach(s => {
      cropNames = cropNames + s.CropDescription + ', ';
    });

    this.stationData.StationType = this.stationTypes.find(s => s.value === this.stationData.StationTypeID)!;
    this.stationData.Region = this.regionCodes.find(s => s.value === this.stationData.RegionID)!;
    // this.stationData.TechnologyTier = this.technologyTiers.find(t => t.value === this.stationData.TechnologyTierTypeId)!;
    this.stationData.TechnologyTierName = this.stationData.TechnologyTierName;

    cropNames = cropNames.trim();

    if (cropNames.lastIndexOf(',') === cropNames.length - 1) {
      cropNames = cropNames.substring(0, cropNames.lastIndexOf(','));
    }

    this.stationData.CropNames = cropNames;

    this.existingPeople = [];
    if (this.stationData.UserResponsibilityStationAssoc) {
      this.stationData.UserResponsibilityStationAssoc.forEach(s => {
        this.existingPeople.push(s.Person);
      });
    }

    if (!this.stationData.StationCode) {
      this.stationData.StationCode = '';
    }

    if (status === RequestStatus.DECLINED || this.validateForm(status !== RequestStatus.PENDING && status !== RequestStatus.DECLINED)) {
      this.stationData.RequestStatus = status;

      this._stationService.saveStation(JSON.stringify(this.stationData))
        .subscribe({
          next: Result => {
            if (Result.Status === 'SUCCESS') {
              const primaryID = this.stationData.StationID;
              this.stationData = Result.Station;
              this.stationData.SaturnLabel = saturnNames;

              const i = this.stationAllData.findIndex(s => s.StationID === primaryID);
              const j = this.expandedItems.findIndex(s => s.StationID === primaryID);
              const k = this.stationCodes.findIndex(s => s.StationID === primaryID);

              if (Result.Station.RequestStatus === RequestStatus.DECLINED) {
                if (i !== -1) {
                  this.stationAllData.splice(i, 1);
                }
                if (j !== -1) {
                  this.expandedItems.splice(j, 1);
                }
                if (k !== -1) {
                  this.stationCodes.splice(k, 1);
                }
              } else {
                this.stationAllData[i] = this.stationData;
                this.expandedItems[j] = this.stationData;

                if (k !== -1) {
                  this.stationCodes.splice(k, 1,
                    ({
                      StationID: this.stationData.StationID,
                      StationCode: this.stationData.StationCode,
                      StationName: this.stationData.StationName,
                      StationCrops: [],
                      CityId: 0,
                      City: '',
                      CountyId: 0,
                      County: '',
                      StateId: 0,
                      State: '',
                      CountryId: 0,
                      Country: '',
                      CountryCode: '',
                      RegionID: '',
                      RequestStatus: this.stationData.RequestStatus,
                      label: this.stationData.StationName + ' - ' + this.stationData.StationCode,
                      value: this.stationData.StationID,
                      GeographicLocale: this.stationData.GeographicLocale,
                      Saturns: this.stationData.Saturns
                    }));
                }

                this.cleanUpCache.emit(true);

                this.getStations();
              }
              this.stationCodes = this.stationCodes.slice();
              this.expandedItems = this.expandedItems.slice();
              this.stationAllData = this.stationAllData.slice();
              this.distinctStationCodesDropdown();

              if (this.selectedStatus === RequestStatus.PENDING && Result.Station.RequestStatus !== RequestStatus.PENDING) {
                this._appService.sendPendingCount(-1);
              }

              this.selectedStatus = status;
              this.DisplaySuccessMessage('Success', Result.Message);
            } else {
              this.stationData.RequestStatus = this.selectedStatus;
              if (Result.Status == "WARNING")
                this.DisplayWarningMessage('Warning', Result.Message);
              else
                this.DisplayErrorMessage('Error', Result.Message);
            }
            this.buildForm(false);
            this.dialogVisible = false;
          },
          error: error => {
            this.stationData.RequestStatus = this.selectedStatus;
            if (error === '') {
              this.DisplayErrorMessage('Error', Constants.NOSERVICE);
            } else {
              this.DisplayErrorMessage('Error', error);
            }
          }
        });
    }
  }

  validateForm(isApprove: any) {
    if (!this.stationForm.StationName || this.stationForm.StationName === '') {
      this.DisplayWarningMessage('Warning', 'R&D Center Name is required...');
      return false;
    }

    if (!this.stationForm.StationCode || this.stationForm.StationCode.toString().trim() === '') {
      this.DisplayWarningMessage('Warning', 'R&D Center Code is required...');
      return false;
    }

    if (this.stationForm.StationCode.toString().trim().length < 2 || this.stationForm.StationCode.toString().trim().length > 4) {
      this.DisplayWarningMessage('Warning', 'R&D Center Code should be 2-4 characters long...');
      return false;
    }

    if (this.stationForm.StationCode && this.stationForm.StationCode.toString().trim() !== '') {
      const sCode = this.stationCodes.find(s =>
        s.StationCode.toString().toLowerCase() === this.stationForm.StationCode.toString().toLowerCase() &&
        s.StationID !== this.stationForm.StationID && !(s.RequestStatus === RequestStatus.RETIRED || s.RequestStatus === RequestStatus.DECLINED));

      if (sCode) {
        this.DisplayWarningMessage('Warning', 'This R&D Center Code already exists. Please enter a new Code...');
        return false;
      }
    }

    if ((!this.stationForm.StationCrops || this.stationForm.StationCrops.length === 0) && this.stationForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'At least one Crop is required...');
      return false;
    }

    if (this.stationForm.RequestStatus === RequestStatus.PENDING && this.selectedStatus !== RequestStatus.PENDING) {
      this.DisplayWarningMessage('Warning', 'Status cannot be reverted back to Pending...');
      return false;
    }

    if (this.stationForm.RequestStatus !== (RequestStatus.PENDING || RequestStatus.RETIRED)) {
      if (isApprove && (!this.stationForm.PRISMLEID || (this.stationForm.PRISMLEID && this.stationForm.PRISMLEID.toString().trim() === ''))) {
        this.DisplayWarningMessage('Warning', 'PRISM LE ID is required for Request Status other than Pending and Retired...');
        return false;
      }

      const idx = this.leIDs.findIndex(s => s.toString() === this.stationForm.PRISMLEID.toString());

      if (isApprove && (this.stationForm.PRISMLEID === '0' || idx === -1)) {
        this.DisplayWarningMessage('Warning', 'PRISM LE ID is not a valid LE ID...');
        return false;
      }
    }

    if ((!this.stationForm.Address || this.stationForm.Address.trim() === '') && this.stationForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Address is required...');
      return false;
    }

    if ((!this.stationForm.Latitude || this.stationForm.Latitude.toString().trim() === '') && this.stationForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Latitude is required...');
      return false;
    }

    if (this.stationForm.Latitude && this.stationForm.Latitude.toString().length > 15) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 15 characters for Latitude...');
      return false;
    }

    if ((!this.stationForm.Longitude || this.stationForm.Longitude.toString().trim() === '') && this.stationForm.RequestStatus !== RequestStatus.RETIRED) {
      this.DisplayWarningMessage('Warning', 'Longitude is required...');
      return false;
    }

    if (this.stationForm.Longitude && this.stationForm.Longitude.toString().length > 15) {
      this.DisplayWarningMessage('Warning', 'Allowed Max length is 15 characters for Longitude...');
      return false;
    }

    return true;
  }

  findSelectedStationIndex(): number {
    return this.stationAllData.indexOf(this.stationData);
  }

  GetLEID(station: StationResultModel) {
    if (station.StationCode) {
      const obj = new LERequestedData();
      obj.ParentComponent = this.parentComponent;
      obj.LEAOIID = 0;

      if (!this.stationFormGroup.controls['stationCode'].dirty) {
        obj.Code = '';
        obj.ProjectLE = this.projectLEResult;
        obj.StationLE = this.stationLEResult;
        obj.SSLLE = this.sSLLEResult;
        obj.LocationProjectCode = this.locationProjectCodeResult;
        obj.ExperimentProjectCode = this.experimentProjectCodeResult;
      } else {
        obj.Code = station.StationCode;
        obj.ProjectLE = [];
        obj.StationLE = [];
        obj.SSLLE = [];
        obj.LocationProjectCode = [];
        obj.ExperimentProjectCode = [];
      }

      obj.OtherObject = JSON.stringify(station);

      this.existingLEIDString = JSON.stringify(obj);
      this.displayLEIDWindow = true;
    } else {
      this.DisplayErrorMessage('Error', 'Please enter the Code first...');
    }
  }

  onaddLEDataClick(data: LERequestedData): void {
    if (!(data == null && data === undefined) && data.ParentComponent === this.parentComponent) {
      this.displayLEIDWindow = false;

      this.stationForm.PRISMLEID = data.LEAOIID.toString();

      this.projectLEResult = data.ProjectLE;

      this.leIDs = [];

      if (this.projectLEResult && this.projectLEResult.length > 0) {
        this.projectLEResult.forEach(s => {
          this.leIDs.push(s.LEId);
        });
      }

      this.stationLEResult = data.StationLE;

      if (this.stationLEResult && this.stationLEResult.length > 0) {
        this.stationLEResult.forEach(s => {
          this.leIDs.push(s.LEId);
        });
      }

      this.sSLLEResult = data.SSLLE;
      this.locationProjectCodeResult = data.LocationProjectCode;
      this.experimentProjectCodeResult = data.ExperimentProjectCode;

      this.existingLEIDString = '';
    }
  }

  ConvertDateToString(dt: Date): string {
    return this._commonService.ConvertDateToString(dt);
  }

  viewComments(station: StationResultModel) {
    this.approvalCommentData = [];

    if (station.ApprovalComments != null) {
      station.ApprovalComments.forEach(element => {
        this.approvalCommentData.push({
          CommentDateString: this.ConvertDateToString(element.CommentDate),
          CommentDate: element.CommentDate,
          Commenter: element.Commenter.DisplayName,
          Comment: element.Comment
        });
      });
    }

    this.approvalCommentData = this.approvalCommentData.slice();
    this.displayComments = true;
    this.StationID = station.StationID;

    this.sourceIdentifier = station.StationID;
  }

  onSaveComment(data: any) {
    this.saveComment(data.comment, data.sourceIdentifier);
  }

  saveComment(comment: string, identifier: string) {
    const stationID = identifier;

    this.disableCommentButton = true;

    const approvalComments = new ApprovalComments();
    approvalComments.Comment = comment;
    approvalComments.RequestTypeID = stationID;

    this._stationService.saveStationComment(JSON.stringify(approvalComments))
      .subscribe({
        next: Result => {
          if (Result.Status === 'SUCCESS') {
            const stationIndex = this.stationAllData
              .findIndex(s => s.StationID.toString() === stationID.toString());

            if (stationIndex !== -1) {
              this.stationAllData[stationIndex] = Result.Station;
              this.stationAllData[stationIndex].ApprovalComments = Result.Station.ApprovalComments.slice();
              this.stationAllData = this.stationAllData.slice();

              const expandedIndex = this.expandedItems
                .findIndex(s => s.StationID = Result.Station.StationID);

              this.expandedItems[expandedIndex] = Result.Station;
              this.expandedItems = this.expandedItems.slice();

              this.approvalCommentData = [];

              if (Result.Station.ApprovalComments != null) {
                Result.Station.ApprovalComments.forEach((element: any) => {
                  this.approvalCommentData.push({
                    CommentDateString: this.ConvertDateToString(element.CommentDate),
                    CommentDate: element.CommentDate,
                    Commenter: element.Commenter.DisplayName,
                    Comment: element.Comment
                  });
                });
              }
            }

            this.approvalCommentData = this.approvalCommentData.slice();
            this.DisplaySuccessMessage('Success', Result.Message);
            this.displayComments = false;
          } else {
            this.DisplayErrorMessage('Error', Result.Message);
          }
          this.disableCommentButton = false;
        },
        error: error => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
          this.disableCommentButton = false;
        }
      });
  }

  public setHeight() {
    const viewPortHeight = document.documentElement.clientHeight;
    const stationGrid = document.getElementById('dvStationGrid');

    if (stationGrid !== null) {
      const offsetHeight = stationGrid.offsetTop;
      if (viewPortHeight <= 650) {
        return 10;
      } else {
        this.panelHeight = ((viewPortHeight) - (offsetHeight) - (200)).toString() + 'px';
        return 10;
      }
    }
    return 0;
  }

  public GetGAAData(event: any) {
    this.gaaData.Latitude = this.stationForm.Latitude;
    this.gaaData.Longitude = this.stationForm.Longitude;
    this.stationData.Latitude = this.stationForm.Latitude;
    this.stationData.Longitude = this.stationForm.Longitude;

    if (this.stationData.Longitude == undefined
      || this.stationData.Longitude.toString().split('.').length < 2
      || this.stationData.Longitude.toString().split(".")[1].length < 2) {
      return;
    }

    if (this.gaaData.Latitude != null) {
      this._commonService.GetGAAData(JSON.stringify(this.gaaData)).subscribe({
        next: (response: GAAData) => {
          if (response != null) {
            this.stationForm.GeographicLocale = response.GeographicLocale;
            this.stationForm.Country = response.Country;
            this.stationForm.State = response.State;
            this.stationForm.CountryId = response.CountryAoiId;
            this.stationForm.StateId = response.StateAoiId;
            this.stationForm.City = response.City;
            this.stationForm.CityId = response.CityAoiId;
          }
          else {
            this.stationForm.GeographicLocale = null;
            this.stationForm.Country = null;
            this.stationForm.State = null;
            this.stationForm.City = null;
            this.stationForm.CountryId = null;
            this.stationForm.StateId = null;
            this.stationForm.CityId = null;
          }
        }, error: (error) => {
          if (error === '') {
            this.DisplayErrorMessage('Error', Constants.NOSERVICE);
          } else {
            this.DisplayErrorMessage('Error', error);
          }
        }, complete: () => { }
      });
    }
  }
}

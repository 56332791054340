import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, ReactiveFormsModule} from "@angular/forms";
import {CommonService} from '../shared/common.service';
import {SharedService} from '../services/shared.service';
import {UserClaim} from '../shared/userClaim';
import {Constants, CropModel, Program, StationLite} from '../shared';
import {RadUser} from '../shared/radUser';
import {MultiSelectModule} from 'primeng/multiselect';
import {PanelModule} from 'primeng/panel';
import {ButtonModule} from 'primeng/button'
import {ProgramService} from '../program/program.service';
import {CreateUserAssociationService} from './create-user-association-service';
import {UserCropStationProgramAssoc} from "../shared/user-crop-station-program-assoc";
import {AccordionModule} from "primeng/accordion";
import {TableModule} from "primeng/table";
import {AutoCompleteModule} from "primeng/autocomplete";
import {Option} from "../shared/option";
import {UserAssoc} from "../shared/user-assoc";
import {ChipsModule} from "primeng/chips";
import {PaginatorModule} from "primeng/paginator";
import {ChipModule} from "primeng/chip";
import {NgForOf, NgIf} from "@angular/common";
import {debounceTime, distinctUntilChanged, Subject} from "rxjs";
import {UserExistingAndNewAssociations} from "../shared/user-existing-and-new-associations";
import {UserAppProgramAssoc} from "../shared/user-app-program-assoc";
import {UserAppStationAssoc} from "../shared/user-app-station-assoc";
import {AppModel} from "../shared/appModel";
import {DialogModule} from "primeng/dialog";
import * as XLSX from 'xlsx';
import {ConfirmAssociationChangesComponent} from "./confirm-association-changes/confirm-association-changes.component";
import {FailedAssociationSubmissionComponent}
  from "./failed-association-submission/failed-association-submission.component";
import {UploadCsvFileComponent} from "./upload-csv-file/upload-csv-file.component";
import {UserAssociationBulkUpload} from "../shared/user-association-bulk-upload";
import {CopyFromUserComponent} from "./copy-from-user/copy-from-user.component";
import {CopyFromUser} from "../shared/copy-from-user";
import {MessagesModule} from "primeng/messages";
import {Message} from "primeng/api";

@Component({
  selector: 'app-create-user-association',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PanelModule,
    MultiSelectModule,
    AutoCompleteModule,
    ButtonModule,
    AccordionModule,
    TableModule,
    ChipsModule,
    PaginatorModule,
    ChipModule,
    NgForOf,
    NgIf,
    DialogModule,
    ConfirmAssociationChangesComponent,
    FailedAssociationSubmissionComponent,
    UploadCsvFileComponent,
    CopyFromUserComponent,
    MessagesModule
  ],
  templateUrl: './create-user-association.component.html',
  styleUrl: './create-user-association.component.scss'
})

export class CreateUserAssociationComponent implements OnInit {
  public userName: string;
  public isROVAdmin = false;
  public isROVUser = false;
  public isROVSuperUser = false;
  public loading = false;
  public loadingUsers = false;
  public findAssocDisabled = true;
  public activeIndex: number[] | undefined = [0];
  public failedDialogVisible = false;
  public confirmChangesDialogVisible: boolean = false;
  public importCsvDialogVisible: boolean = false;
  public copyFromUserDialogVisible: boolean = false;
  public failedAddAssoc: UserCropStationProgramAssoc[] = [];
  public failedRemovalAssoc: UserCropStationProgramAssoc[] = [];

  public users: RadUser[] = [];
  public crops: CropModel[] = [];
  public stations: StationLite[] = [];
  public programs: Program[] = [];
  public apps: AppModel[] = [];

  public existingAssociations: UserAssoc[] = [];
  public newAssociations: UserAssoc[] = [];
  public existingAssociationsFiltered: UserAssoc[] = [];
  public newAssociationsFiltered: UserAssoc[] = [];
  public associationsToRemove: UserCropStationProgramAssoc[] = [];
  public removedAssociations: UserAssoc[] = [];

  public showFilter: boolean = false;
  public searchSubject$ = new Subject<string>();
  public failedProgramAssoc: UserAppProgramAssoc[] = [];
  public failedStationAssoc: UserAppStationAssoc[] = [];
  public failedToRemoveProgramAssoc: UserAppProgramAssoc[] = [];
  public failedToRemoveStationAssoc: UserAppStationAssoc[] = [];

  public programAdd: boolean = false;
  public stationAdd: boolean = false;
  public programRemove: boolean = false;
  public stationRemove: boolean = false;

  public importFile: File | null = null;

  public assocMsgs: Message[] = [];
  public infoMessages: Message[] = [{
    severity: "info",
    summary: "",
    detail: "To add station associations, please specify at least one of each: User, Crop, Station."
  }];

  public userAssocForm = this.fb.nonNullable.group({
    selectedUsers: new FormControl(),
    selectedCrops: new FormControl(),
    selectedStations: new FormControl(),
    selectedPrograms: new FormControl(),
    filter: new FormControl()
  })

  constructor(private fb: FormBuilder, private _commonService: CommonService,
              private _sharedService: SharedService, private _programService: ProgramService,
              private _userAssocService: CreateUserAssociationService) {
  }

  ngOnInit(): void {
    this.searchSubject$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(search => {
      this.filterAssociations(search);
    });

    this.getLoginInfo();
    this.loadFormData();
  }

  onChange() {
    let userLen = this.userAssocForm.get('selectedUsers')?.value?.length;
    let cropLen = this.userAssocForm.get('selectedCrops')?.value?.length;
    let stationLen = this.userAssocForm.get('selectedStations')?.value?.length;
    let programLen = this.userAssocForm.get('selectedPrograms')?.value?.length;

    this.findAssocDisabled = (!userLen || userLen == 0)
      && (!cropLen || cropLen == 0)
      && (!stationLen || stationLen == 0)
      && (!programLen || programLen == 0);
  }

  findAssociations() {
    this.assocMsgs = [];
    if ((!this.userAssocForm.controls.selectedUsers.value || this.userAssocForm.controls.selectedUsers.value.length === 0) &&
      (!this.userAssocForm.controls.selectedCrops.value || this.userAssocForm.controls.selectedCrops.value.length === 0) &&
      (!this.userAssocForm.controls.selectedStations.value || this.userAssocForm.controls.selectedStations.value.length === 0) &&
      (!this.userAssocForm.controls.selectedPrograms.value || this.userAssocForm.controls.selectedPrograms.value.length === 0)) {
      this.existingAssociations = this.existingAssociationsFiltered = this.newAssociations = this.newAssociationsFiltered = [];
      this.setActiveIndexes();
      return;
    }
    const filter: string | null | undefined = this.userAssocForm.controls.filter.value;
    const selectedUsers: string[] = [];
    const selectedCrops: string[] = [];
    const selectedStations: string[] = [];
    const selectedPrograms: string[] = [];

    this.resetActiveIndexes();
    this.associationsToRemove = [];

    if (this.userAssocForm.controls.selectedUsers.value) {
      this.userAssocForm.controls.selectedUsers.value.forEach((usr: { value: string; }) => {
        selectedUsers.push(usr.value);
      });
    }
    if (this.userAssocForm.controls.selectedCrops.value) {
      this.userAssocForm.controls.selectedCrops.value.forEach((crp: { value: string; }) => {
        selectedCrops.push(crp.value);
      });
    }
    if (this.userAssocForm.controls.selectedStations.value) {
      this.userAssocForm.controls.selectedStations.value.forEach((sta: { value: string; }) => {
        selectedStations.push(sta.value);
      });
    }
    if (this.userAssocForm.controls.selectedPrograms.value) {
      this.userAssocForm.controls.selectedPrograms.value.forEach((pgm: Program) => {
        selectedPrograms.push(pgm.value);
      });
    }

    this._userAssocService.loadAssociations(selectedUsers, selectedCrops, selectedPrograms, selectedStations)
      .subscribe({
        next: (data: UserExistingAndNewAssociations) => {
          this.newAssociations = this.createRows(data.NewAssociations);
          this.existingAssociations = this.createRows(data.ExistingAssociations);

          if (filter && filter.length > 0) {
            this.filterAssociations(filter)
          } else {
            this.newAssociationsFiltered = this.newAssociations;
            this.existingAssociationsFiltered = this.existingAssociations;
          }

          if(this.newAssociationsFiltered.length === 0) {
            this.assocMsgs = [{
              severity: "info",
              summary: "No new associations found",
              detail: "No new associations found for the selected users, crops, programs and stations"
            }];
          }

          this.setActiveIndexes();
        }
      });
  }

  onFilterChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.searchSubject$.next(target.value);
  }

  onUserFilter(event: any): void {
    const inputValue = event.filter;

    if(inputValue.includes(',')){
      let pastedValues = inputValue.split(',').map((value:string)=> value.trim().toUpperCase());
      this.applyPastedValues(pastedValues);
    }
    else if(inputValue.includes(' ')){
      let pastedValues = inputValue.split(' ').map((value:string)=> value.trim().toUpperCase());
      this.applyPastedValues(pastedValues);
    }
  }

  applyPastedValues(pastedValues: any): void {
    if(pastedValues){
      const matches = this.users.filter(f=> pastedValues.includes(f.LoginName.toUpperCase()));

      let current: RadUser[] = this.userAssocForm.controls.selectedUsers.value;
      if(current){
        let all: RadUser[] = [];
        current.forEach(f=>{
          all.push(f);
        });

        matches.forEach(f=> {
          let found = current.find(s=>s.Id === f.Id);
          if(!found)
            all.push(f);
        });

        this.userAssocForm.controls.selectedUsers.setValue([...all]);
      }
      else {
        this.userAssocForm.controls.selectedUsers.setValue([...matches]);
      }

      this.onChange();
    }
  }

  setActiveIndexes() {
    if (this.existingAssociations.length > 0 && this.newAssociations.length > 0) {
      this.activeIndex = [1, 2];
      this.showFilter = true;
    } else if (this.existingAssociations.length > 0) {
      this.activeIndex = [1];
      this.showFilter = true;
    } else if (this.newAssociations.length > 0) {
      this.activeIndex = [2];
      this.showFilter = true;
    } else {
      this.activeIndex = [0];
      this.showFilter = false;
    }
  }

  resetActiveIndexes() {
    this.activeIndex = [0];
    this.showFilter = false;
  }

  filterAssociations(argument: string): void {
    const filterByAssociations = (association: any, searchText: string): boolean => {
      const lowercasedSearchText = searchText.toLowerCase();
      const matchesUserOrCrop: boolean =
        association.user.label.toLowerCase().includes(lowercasedSearchText) ||
        association.crop.label.toLowerCase().includes(lowercasedSearchText);

      const matchesProgram = association.programs.some((program: any) => program.label.toLowerCase().includes(lowercasedSearchText));
      const matchesStation = association.stations.some((station: any) => station.label.toLowerCase().includes(lowercasedSearchText));

      return matchesUserOrCrop || matchesProgram || matchesStation;
    };

    this.newAssociationsFiltered = this.newAssociations.filter(association => filterByAssociations(association, argument));

    this.existingAssociationsFiltered = this.existingAssociations.filter(association => filterByAssociations(association, argument));
  }

  getLoginInfo() {
    let localRoles = this._sharedService.getSessionStorageValue(Constants.UIUSERROLE);
    if (localRoles !== '' && localRoles !== null) {
      const userClaim = JSON.parse(localRoles || '{}') as UserClaim;
      this.userName = userClaim.username;
      this.isROVAdmin = userClaim.isROVAdmin;
      this.isROVUser = userClaim.isROVUser;
    }
  }

  loadFormData() {
    this.loadUsersList();
    this.loadCropStationProgramLists();
    this.loadApps();
  }

  loadApps() {
    this._userAssocService.getApps().subscribe({
      next: (data: AppModel[]) => {
        this.apps = data;
      }
    })
  }

  loadCropStationProgramLists() {
    this.loading = true;

    this._programService.bindProgramDropdownLists().subscribe({
      next: data => {
        if (data.Crops && data.Crops.length > 0) {
          this.crops = data.Crops;
          this.crops.forEach(item => {
            item.value = item.CropID;
            item.label = item.CropDescription;
          });
          this.crops.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }

        if (data.ProgramCodes && data.ProgramCodes.length > 0) {
          this.programs = data.ProgramCodes;
          this.programs.forEach(item => {
            let cropName = this.crops.find(c => c.value === item.CropID)?.label ?? "*";
            item.value = item.ProgramID;
            item.label = `${item.ProgramCode} - ${item.ProgramName} (${cropName})`;
          });
          this.programs.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }

        if (data.Stations && data.Stations.length > 0) {
          this.stations = data.Stations;
          this.stations.forEach(item => {
            item.value = item.StationID;
            item.label = item.StationCode + ' - ' + item.StationName;
          });
          this.stations.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }
      },
      error: error => {
        if (error === '') {
          this.displayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.displayErrorMessage('Error', error);
        }
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  loadUsersList() {
    this.loadingUsers = true;

    this._userAssocService.loadUsers().subscribe({
      next: data => {
        if (data && data.length > 0) {
          this.users = data;
          this.users.forEach(item => {
            item.value = item.Id;
            item.label = item.LoginName;
          });
          this.users.sort((a, b) => a.label.localeCompare(b.label, undefined, {sensitivity: 'base'}));
        }
      },
      error: error => {
        if (error === '') {
          this.displayErrorMessage('Error', Constants.NOSERVICE);
        } else {
          this.displayErrorMessage('Error', error);
        }

        this.loadingUsers = false;
      },
      complete: () => {
        this.loadingUsers = false;
      }
    });
  }

  createRows(data: UserCropStationProgramAssoc[]): UserAssoc[] {
    const response: UserAssoc[] = [];
    const users: Option[] = [];
    const crops: Option[] = [];
    let tempUsers = data.filter((item, index, self) => {
      return index === self.findIndex(p => p.userId === item.userId);
    });
    let tempCrops = data.filter((item, index, self) => {
      return index === self.findIndex(p => p.cropId === item.cropId);
    });
    tempUsers.forEach(user => {
      users.push({label: user.userLoginName, value: user.userId});
    });
    tempCrops.forEach(crop => {
      crops.push({label: crop.cropDescription, value: crop.cropId});
    });

    let id: number = 0;
    users.forEach(user => {
      crops.forEach(crop => {
        let stations: Option[] = [];
        let programs: Option[] = [];
        data.forEach(item => {
          if (item.userId == user.value && item.cropId == crop.value) {
            if (item.stationId) {
              let label = this.getStationLabel(item.stationCode, item.stationName);
              stations.push({code: item.stationCode, label: label, value: item.stationId});
            }
            if (item.programId) {
              let label = this.getProgramLabel(item.programCode, item.programName, crop.label);
              programs.push({code: item.programCode, label: label, value: item.programId});
            }
          }
        });
        id++;
        if (stations.length + programs.length > 0) {
          response.push({id: id, user: user, crop: crop, stations: stations, programs: programs});
        }
      });
    });


    return response;
  }

  onChipRemoved(type: string, id: number, option: Option): void {
    let field = type.includes("Sta") ? "Sta" : "Pgm";
    let newExi = type.includes("new") ? "New" : "Exi";

    if (newExi === "New") {
      this.removeAssoc(this.newAssociations, option, id, field);
    }
    if (newExi === "Exi") {
      this.removeAssoc(this.existingAssociations, option, id, field, true);
    }
  }

  removeAssoc(list: UserAssoc[], option: Option, id: number, type: string, isExistent: boolean = false): void {
    const item = list.find(f => f.id === id);
    if (item) {
      if (isExistent) {    // Should be remo
        this.addToRemoved(item, type, option);
      }
      let editingItem = {...item};
      if (type === "Sta") {
        editingItem.stations = editingItem.stations.filter(s => s.value !== option.value);
      } else if (type === "Pgm") {
        editingItem.programs = editingItem.programs.filter(s => s.value !== option.value);
      } else {
        return;
      }

      const index = list.findIndex(x => x.id === id);
      if (index !== -1) {
        if (editingItem.stations.length === 0 && editingItem.programs.length === 0) {
          list.splice(index, 1);
        } else {
          list[index] = editingItem;
        }

      }
    }
  }

  private addToRemoved(item: UserAssoc, type: string, option: Option) {
    let crop = this.crops.find(s => s.CropID === item.crop.value)

    this.associationsToRemove.push({
      userId: item.user.value,
      userLoginName: item.user.label,
      cropId: crop?.CropID ?? "",
      cropName: crop?.CropName ?? "",
      cropDescription: crop?.CropDescription ?? "",
      stationId: type === "Sta"
        ? option.value ?? ""
        : null,
      stationCode: type === "Sta"
        ? option.label
        : null,
      stationName: type === "Sta"
        ? ""
        : null,
      programId: type === "Pgm"
        ? option.value
        : null,
      programCode: type === "Pgm"
        ? option.label
        : null,
      programName: type === "Pgm"
        ? ""
        : null
    });
  }

  reset(): void {
    this.assocMsgs = [];

    this.userAssocForm.controls.filter.setValue("");
    this.searchSubject$.next("");

    this.existingAssociations = this.existingAssociationsFiltered = [];
    this.newAssociations = this.newAssociationsFiltered = [];
    this.resetActiveIndexes();
    this.associationsToRemove = [];
    this.importFile = null;
  }

  showConfirmation() {
    this.removedAssociations = this.createRows(this.associationsToRemove);
    this.confirmChangesDialogVisible = true;
  }

  submit(): void {
    const programToAdd: UserAppProgramAssoc[] = [];
    const stationToAdd: UserAppStationAssoc[] = [];
    const programToRemove: UserAppProgramAssoc[] = [];
    const stationToRemove: UserAppStationAssoc[] = [];
    const appProgram: AppModel | undefined = this.apps.find(s => s.appType == "Program");
    const appStation: AppModel | undefined = this.apps.find(s => s.appType == "Station");

    if (!appProgram || !appStation) {
      this.displayErrorMessage("Error", "No app found for Program or Station");
      return;
    }

    this.failedStationAssoc = [];
    this.failedProgramAssoc = [];
    this.failedToRemoveProgramAssoc = [];
    this.failedToRemoveStationAssoc = [];

    this.newAssociationsFiltered.forEach(item => {
      item.programs.forEach(p => {
        if (programToAdd.some(s =>
          s.programId === p.value &&
          s.userId === item.user.value &&
          s.appId === appProgram.id)) {
          return;
        }
        programToAdd.push({
          userId: item.user.value,
          appId: appProgram.id,
          programId: p.value,
        });
      });
      item.stations.forEach(s => {
        if (stationToAdd.some(x =>
          x.userId === item.user.value &&
          x.appId === appStation.id &&
          x.stationId === s.value &&
          x.cropId === item.crop.value)) {
          return
        }
        stationToAdd.push({
          userId: item.user.value,
          appId: appStation.id,
          stationId: s.value,
          cropId: item.crop.value,
        });
      });
    });

    this.removedAssociations.forEach(item => {
      item.programs.forEach(p => {
        if (programToRemove.some(s =>
          s.programId === p.value &&
          s.userId === item.user.value &&
          s.appId === appProgram.id)) {
          return;
        }
        programToRemove.push({
          userId: item.user.value,
          appId: appProgram.id,
          programId: p.value,
        });
      });
      item.stations.forEach(t => {
        if (stationToRemove.some(x =>
          x.userId === item.user.value &&
          x.appId === appStation.id &&
          x.stationId === t.value &&
          x.cropId === item.crop.value)) {
          return;
        }
        stationToRemove.push({
          userId: item.user.value,
          appId: appStation.id,
          stationId: t.value,
          cropId: item.crop.value,
        });
      });
    });

    this.programAdd = programToAdd.length > 0;
    this.programRemove = programToRemove.length > 0;
    this.stationAdd = stationToAdd.length > 0;
    this.stationRemove = stationToRemove.length > 0;

    if (this.programAdd) {
      this._userAssocService.saveProgramAssociations(programToAdd).subscribe({
        next: (data: UserAppProgramAssoc[]) => {
          this.failedProgramAssoc = data;
          this.checkSubmissionResponses("programAdd");
        }
      });
    }

    if (this.stationAdd) {
      this._userAssocService.saveStationAssociations(stationToAdd).subscribe({
        next: (data: UserAppStationAssoc[]) => {
          this.failedStationAssoc = data;
          this.checkSubmissionResponses("stationAdd");
        }
      });
    }

    if (this.programRemove) {
      this._userAssocService.removeProgramAssociations(programToRemove).subscribe({
        next: (data: UserAppProgramAssoc[]) => {
          this.failedToRemoveProgramAssoc = data;
          this.checkSubmissionResponses("programRemove");
        }
      })
    }

    if (this.stationRemove) {
      this._userAssocService.removeStationAssociations(stationToRemove).subscribe({
        next: (data: UserAppStationAssoc[]) => {
          this.failedToRemoveStationAssoc = data;
          this.checkSubmissionResponses("stationRemove");
        }
      })
    }
  }

  checkSubmissionResponses(process: string): void {
    switch (process) {
      case "programAdd":
        this.programAdd = false;
        break;
      case "programRemove":
        this.programRemove = false;
        break;
      case "stationAdd":
        this.stationAdd = false;
        break;
      case "stationRemove":
        this.stationRemove = false;
        break;
      default:
        break;
    }

    let ctrl = (this.programAdd ? 1 : 0) +
      (this.programRemove ? 1 : 0) +
      (this.stationAdd ? 1 : 0) +
      (this.stationRemove ? 1 : 0);

    this.failedAddAssoc = [];
    let failed = false;

    if (ctrl === 0) {
      if (this.failedStationAssoc.length > 0) {
        failed = true;
        this.failedStationAssoc.forEach(item => {
          let user = this.users.find(u => u.value === item.userId);
          let crop = this.crops.find(c => c.value === item.cropId);
          let station = this.stations.find(s => s.value === item.stationId);
          this.failedAddAssoc.push({
            userId: user?.Id ?? "",
            userLoginName: user?.LoginName ?? "",
            cropId: crop?.CropID ?? "",
            cropName: crop?.CropName ?? "",
            cropDescription: crop?.CropDescription ?? "",
            stationId: station?.StationID ?? "",
            stationCode: station?.StationCode ?? "",
            stationName: station?.StationName ?? "",
            programId: "",
            programCode: "",
            programName: ""
          });
        });
      }

      if (this.failedProgramAssoc.length > 0) {
        failed = true;
        this.failedProgramAssoc.forEach(item => {
          let user = this.users.find(u => u.value === item.userId);
          let program = this.programs.find(s => s.value === item.programId);
          let crop = this.crops.find(c => c.value === program?.CropID);
          this.failedAddAssoc.push({
            userId: user?.Id ?? "",
            userLoginName: user?.LoginName ?? "",
            cropId: crop?.CropID ?? "",
            cropName: crop?.CropName ?? "",
            cropDescription: crop?.CropDescription ?? "",
            stationId: "",
            stationCode: "",
            stationName: "",
            programId: program?.ProgramID ?? "",
            programCode: program?.ProgramCode ?? "",
            programName: program?.ProgramName ?? ""
          });
        });
      }

      if (this.failedToRemoveStationAssoc.length > 0) {
        failed = true;
        this.failedToRemoveStationAssoc.forEach(item => {
          let user = this.users.find(u => u.value === item.userId);
          let crop = this.crops.find(c => c.value === item.cropId);
          let station = this.stations.find(s => s.value === item.stationId);
          this.failedRemovalAssoc.push({
            userId: user?.Id ?? "",
            userLoginName: user?.LoginName ?? "",
            cropId: crop?.CropID ?? "",
            cropName: crop?.CropName ?? "",
            cropDescription: crop?.CropDescription ?? "",
            stationId: item.stationId ?? "",
            stationCode: station?.StationCode ?? "*",
            stationName: station?.StationName ?? "*",
            programId: "",
            programCode: "",
            programName: ""
          });
        });
      }

      if (this.failedToRemoveProgramAssoc.length > 0) {
        failed = true;
        this.failedProgramAssoc.forEach(item => {
          let user = this.users.find(u => u.value === item.userId);
          let program = this.programs.find(s => s.value === item.programId);
          let crop = this.crops.find(c => c.value === program?.CropID);
          this.failedRemovalAssoc.push({
            userId: user?.Id ?? "",
            userLoginName: user?.LoginName ?? "",
            cropId: crop?.CropID ?? "",
            cropName: crop?.CropName ?? "",
            cropDescription: crop?.CropDescription ?? "",
            stationId: "",
            stationCode: "",
            stationName: "",
            programId: item.programId ?? "",
            programCode: program?.ProgramCode ?? "*",
            programName: program?.ProgramName ?? "*"
          });
        });
      }
    }

    if (ctrl === 0) {
      this.confirmChangesDialogVisible = false;
      if (failed) {
        this.failedDialogVisible = true;
      } else {
        this.displaySuccessMessage("Success", "Successfully saved associations");
      }
    }

    this.findAssociations();
  }

  exportFailedToExcel(): void {
    let addFailed: any[] = [];
    let removalFailed: any[] = [];
    this.failedAddAssoc.forEach(item => {
      addFailed.push({
        User: item.userLoginName,
        Crop: item.cropDescription,
        Station: `${item.stationCode} - ${item.stationName}`,
        Program: `${item.programCode} - ${item.programName}`
      });
    })

    this.failedRemovalAssoc.forEach(item => {
      removalFailed.push({
        User: item.userLoginName,
        Crop: item.cropDescription,
        Station: `${item.stationCode} - ${item.stationName}`,
        Program: `${item.programCode} - ${item.programName}`
      })
    })

    const wsAddFailed: XLSX.WorkSheet = XLSX.utils.json_to_sheet(addFailed);
    const wsRemovalFailed: XLSX.WorkSheet = XLSX.utils.json_to_sheet(removalFailed);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsAddFailed, 'Associations Failed to Add');
    XLSX.utils.book_append_sheet(wb, wsRemovalFailed, 'Associations Failed to Remove');
    XLSX.writeFile(wb, 'failed_submissions.xlsx');
  }

  exportToExcel(): void {
    let existingAssoc: any[] = [];
    let newAssoc: any[] = [];
    this.existingAssociationsFiltered.forEach(item => {
      this.extractAssoc(item, existingAssoc);
    })
    this.newAssociationsFiltered.forEach(item => {
      this.extractAssoc(item, newAssoc);
    });
    const wsExisting: XLSX.WorkSheet = XLSX.utils.json_to_sheet(existingAssoc);
    const wsNew: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newAssoc);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsExisting, 'Existing Associations');
    XLSX.utils.book_append_sheet(wb, wsNew, 'New Associations');
    XLSX.writeFile(wb, 'existing_new_associations.xlsx');
  }

  private extractAssoc(item: UserAssoc, existingAssoc: any[]) {
    item.stations.forEach(station => {
      existingAssoc.push({
        User: item.user.label,
        Crop: item.crop.label,
        Station: station.code,
        Program: ''
      });
    });
    item.programs.forEach(program => {
      existingAssoc.push({
        User: item.user.label,
        Crop: item.crop.label,
        Station: '',
        Program: program.code
      });
    });
  }

  processFile($event: any): void {
    let files = $event.target.files;
    if (this.isValidCsvExcelFile(files[0])) {
      this.importFile = $event.target.files[0];
      this.importCsvDialogVisible = true;
    }

    this.userAssocForm.controls.selectedUsers.setValue([]);
    this.userAssocForm.controls.selectedCrops.setValue([]);
    this.userAssocForm.controls.selectedStations.setValue([]);
    this.userAssocForm.controls.selectedPrograms.setValue([]);

    $event.target.value = "";
  }

  isValidCsvExcelFile(file: any): boolean {
    return file.name.toLowerCase().endsWith(".csv") ||
      file.name.toLowerCase().endsWith(".xlsx");
  }

  processImportedData(input: UserAssociationBulkUpload[]): void {
    this.importCsvDialogVisible = false;
    this.importFile = null;
    let newAssociations = input.filter(i => !i.exist);
    let existentAssociations = input.filter(i => i.exist);

    this.newAssociations = newAssociations.length > 0
      ? this.convertToRows(newAssociations)
      : [];
    this.existingAssociations = existentAssociations.length > 0
      ? this.convertToRows(existentAssociations)
      : [];

    this.newAssociationsFiltered = this.newAssociations;
    this.existingAssociationsFiltered = this.existingAssociations;

    this.setActiveIndexes();
  }

  convertToRows(input: UserAssociationBulkUpload[]): UserAssoc[] {
    let list = this.sort(input);
    let ctrlUser: string = "";
    let ctrlCrop: string = "";
    let rows: UserAssoc[] = [];
    let id: number = 0;
    let optUser: Option = new Option();
    let optCrop: Option = new Option();
    let optStations: Option[] = [];
    let optPrograms: Option[] = [];
    list.forEach(item => {
      if (ctrlUser !== item.userId) {
        if (id !== 0) {
          rows.push({id: id, user: optUser, crop: optCrop, stations: optStations, programs: optPrograms});
        }
        id++;
        ctrlUser = item.userId;
        ctrlCrop = item.cropId;
        let user = this.users.find(u => u.Id === item.userId);
        let crop = this.crops.find(c => c.CropID === item.cropId);
        optUser = {value: user?.value, label: user?.label ?? "",};
        optCrop = {value: crop?.value, label: crop?.CropDescription ?? "",};
        optStations = [];
        optPrograms = [];
      }
      if (ctrlCrop != item.cropId) {
        if (id !== 0) {
          rows.push({id: id, user: optUser, crop: optCrop, stations: optStations, programs: optPrograms});
        }
        id++;
        ctrlCrop = item.cropId;
        let crop = this.crops.find(c => c.CropID === item.cropId);
        optCrop = {value: crop?.value, label: crop?.CropDescription ?? "",};
        optStations = [];
        optPrograms = [];
      }
      if (item.stationId !== null && item.stationId !== undefined && item.stationId !== "") {
        let station = this.stations.find(s => s.StationID === item.stationId);
        if (station) {
          let label = this.getStationLabel(station.StationCode, station.StationName);
          optStations.push({value: station.value, label: label});
        }
      }
      if (item.programId !== null && item.programId !== undefined && item.programId !== "") {
        let program = this.programs.find(p => p.ProgramID === item.programId);
        if (program) {
          let label = this.getProgramLabel(program.ProgramCode, program.ProgramName, optCrop?.label ?? "");
          optPrograms.push({value: program.value, label: label});
        }
      }
    });
    if (optUser && optCrop)
      rows.push({id: id, user: optUser, crop: optCrop, stations: optStations, programs: optPrograms});

    return rows;
  }

  getStationLabel(stationCode: string | null | undefined, stationName: string | null | undefined): string {
    return (stationName && stationName !== "")
      ? `${stationCode} - ${stationName}`
      : stationCode ?? "";
  }

  getProgramLabel(programCode: string | null | undefined,
                  programName: string | null | undefined,
                  crop: string): string {
    return (programName && programName !== "")
      ? `${programCode} - ${programName} (${crop})`
      : programCode ?? "";
  }

  sort(list: UserAssociationBulkUpload[]): UserAssociationBulkUpload[] {
    return list.sort((a, b) => {
      if (a.userId !== b.userId) {
        return a.userId.localeCompare(b.userId)
      }
      return a.cropId.localeCompare(b.cropId);
    });
  }

  copyFromUser(): void {
    this.copyFromUserDialogVisible = true;
  }

  closeCopyFromUser(): void {
    this.copyFromUserDialogVisible = false;
  }

  processAssociationsForCopy(response: CopyFromUser): void {
    this.copyFromUserDialogVisible = false;

    this._userAssocService.loadAssociationsForCopy(response.sourceUser, response.targetUsers)
      .subscribe({
        next: (data: UserExistingAndNewAssociations) => {
          this.newAssociations = this.newAssociationsFiltered = this.createRows(data.NewAssociations);
          this.existingAssociations = this.existingAssociationsFiltered = this.createRows(data.ExistingAssociations);

          this.userAssocForm.controls.filter.setValue("");
          this.userAssocForm.controls.selectedUsers.setValue([]);
          this.userAssocForm.controls.selectedCrops.setValue([]);
          this.userAssocForm.controls.selectedPrograms.setValue([]);
          this.userAssocForm.controls.selectedStations.setValue([]);
          this.setActiveIndexes();
        }
      });
  }

  closeImportCsvDialog(): void {
    this.importCsvDialogVisible = false;
  }

  displayErrorMessage(title: string, message: string) {
    this._commonService.DisplayErrorMessage(title, message);
  }

  displayInfoMessage(title: string, message: string) {
    this._commonService.DisplayInfoMessage(title, message);
  }

  displaySuccessMessage(title: string, message: string) {
    this._commonService.DisplaySuccessMessage(title, message);
  }
}

import {Component, EventEmitter, Input, Output, output} from '@angular/core';
import {UserAssoc} from "../../shared/user-assoc";
import {PanelModule} from "primeng/panel";
import {ChipModule} from "primeng/chip";
import {NgForOf, NgIf} from "@angular/common";
import {TableModule} from "primeng/table";
import {Button, ButtonDirective} from "primeng/button";

@Component({
  selector: 'app-confirm-association-changes',
  standalone: true,
  imports: [
    PanelModule,
    ChipModule,
    NgForOf,
    TableModule,
    ButtonDirective,
    NgIf
  ],
  templateUrl: './confirm-association-changes.component.html',
  styleUrl: './confirm-association-changes.component.scss'
})
export class ConfirmAssociationChangesComponent {
  @Input({required: true}) newAssociations: UserAssoc[] = [];
  @Input({required: true}) removedAssociations: UserAssoc[] = [];
  @Output() onConfirm = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  confirm() {
    this.onConfirm.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}

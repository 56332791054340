import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from "primeng/api";
import { LoadingService } from '../services/loadingService';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private _messageService: MessageService,
    private loader: LoadingService,
    private spinnerService: SpinnerService,
  ) {}

  get(url: string): Observable<any> {
    return this.get2(url, 'Loading...');
  }

  get2(url: string, loadingText: string): Observable<any> {
    this.loader.setMessage(loadingText);
    this.spinnerService.show();
    return new Observable((subscriber) => {
      this.http
        .get(url)
        .pipe(finalize(() => this.loader.clearMessage()))
        .subscribe({
          next: (response: any) => subscriber.next(response),
          error: (error: any) => {
            this.DisplayErrorMessage('Error', error.message);
            subscriber.error(error.message);
            this.spinnerService.hide();
          },
          complete: () => {
            subscriber.complete();
            this.spinnerService.hide();
          }
        });
    });
  }

  post(url: string, body: any, options?: any): Observable<any> {
    return this.post2(url, body, 'Loading...', options);
  }

  post2(url: string, body: any, loadingText: string, options?: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    this.loader.setMessage(loadingText);
    this.spinnerService.show();
    return new Observable((subscriber) => {
      this.http
        .post(url, body, options ?? httpOptions)
        .pipe(finalize(() => this.loader.clearMessage()))
        .subscribe({
          next: (response: any) => subscriber.next(response),
          error: (error: any) => {
            this.spinnerService.hide();
            this.DisplayErrorMessage('Error', error.message);
            subscriber.error(error);
          },
          complete: () => {
            this.spinnerService.hide();
            subscriber.complete();
          },
        });
    });
  }

  public DisplayErrorMessage(title: string, message: string) {
    this._messageService.add({ severity: 'error', summary: title, detail: message });
  }
}

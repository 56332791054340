<p-panel *ngIf="verifyNewRolesDialogVisible === false">
    <p-header id="pnlHdrImport">
        <span>Import Results</span>
    </p-header>

    <p-table *ngIf="csvData.length > 0" [value]="csvData" columnResizeMode="fit">
        <ng-template pTemplate="header">
        <tr>
            <th pSortableColumn="user"> User
                <p-sortIcon field="user"></p-sortIcon>
            </th>
            <th pColumn="roles">Roles</th>
            <th>Valid</th>
            <th>Notes</th>
        </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
        <tr>
            <td>{{ row.LoginName }}</td>
            <td>
                <div class="chip-container">
                    <div *ngFor="let role of row.RolesArray">
                        <p-chip label="{{role.label}}" [removable]="true" (onRemove)="onRoleChipRemoved(row.LoginName, role)"/>
                    </div>
                </div>
            </td>
            <td>
                <i class="pi" [ngClass]="{
                    'text-red-500 pi-times-circle': (row.Status === 0),
                    'text-green-500 pi-check-circle': (row.Status === 1), 
                    'text-orange-500 pi-pause-circle': (row.Status === 2) }"></i>
            </td>
            <td>{{ row.Notes }}</td>
        </tr>
        </ng-template>
    </p-table>

    <p-footer>
        <div class="grid">
            <div class="col-6"></div>
            <div class="col-3">
                <button id="btnCancel" type="button"
                        class="p-button-warning alignRight"
                        style="margin-right: 15px"
                        pButton icon="fa fa-close"
                        (click)="cancel()" label="Cancel"></button>
            </div>
            <div class="col-3">
                <button id="btnSubmit" type="button"
                        class="p-button-success alignRight"
                        pButton icon="fa fa-save"
                        [disabled]="!isUploadDataValid"
                        (click)="submit()" label="Submit"></button>
            </div>
        </div>
    </p-footer>
</p-panel>

<form id="frmVerifyRoles" [formGroup]="verifyRoleForm" *ngIf="verifyNewRolesDialogVisible === true">
    <p-panel>
      <p-header id="pnlHdrVerifyRoles">
        <span>Verify Roles</span>
      </p-header>

      <div style="min-height: 150px">
        <div class="grid">
          <div class="col-8">
            <label>Choose Non-Existing Role(s) to Add</label>
          </div>
          <div class="col-6">
            <span class="p-fluid">
              <p-multiSelect id="msRoles" formControlName="selectedRoles"
                  [options]="this.newRoles" optionLabel="label" placeholder="Choose one or more roles"
                  [filter]="true" [resetFilterOnHide]="true"
                  display="chip" show [showClear]="true" [maxSelectedLabels]="1000"/>
            </span>
          </div>
          <div class="col-12">
            <label>Clicking Submit with no selections will clear all non-existing roles</label>
          </div>
        </div>
      </div>
  
      <p-footer>
        <div class="grid">
            <div class="col-6"></div>
            <div class="col-3">
                <button id="btnCancel" type="button"
                    class="p-button-warning alignRight"
                    style="margin-right: 15px"
                    pButton icon="fa fa-close"
                    (click)="verifyNewRolesDialogVisible = false" label="Cancel"></button>
            </div>
            <div class="col-3">
                <button id="btnSubmit" type="button"
                    class="p-button-success alignRight"
                    pButton icon="fa fa-save"
                    (click)="validateNewRoles()" label="Verify Roles"></button>
            </div>
        </div>
      </p-footer>
    </p-panel>
</form>
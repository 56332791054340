<form id="frmCopyFromUser"
      #frm="ngForm"
      [formGroup]="copyFromUserForm">
  <p-panel>
    <div style="min-height: 350px">
      <div class="grid">
        <div class="controlLabel col-3">
          <label id="lblSourceUser">Copy From</label>
        </div>
        <div class="col-7">
        <span class="p-fluid">
          <p-dropdown id="sourceUser"
                      [options]="users"
                      optionLabel="label"
                      formControlName="sourceUser"
                      [filter]="true"
                      [showClear]="true"
                      (onChange)="onChanges($event)"
                      [placeholder]="'Select a User'"></p-dropdown>
        </span>
        </div>

        <div class="controlLabel col-3">
          <label id="lblTargetUsers">Copy To</label>
        </div>
        <div class="col-7">
        <span class="p-fluid">
          <p-multiSelect id="msTargetUsers"
                         [options]="filteredUsers"
                         formControlName="targetUsers"
                         display="chip"
                         optionLabel="label"
                         [filter]="true"
                         [showClear]="true"
                         [placeholder]="'Select one or more Users'"
                         [resetFilterOnHide]="true"
                         [maxSelectedLabels]="1000">

          </p-multiSelect>
        </span>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="grid">
        <div class="col-6"></div>
        <div class="col-3">
          <button id="btnCancel" type="button"
                  class="p-button-warning alignRight"
                  style="margin-right: 15px"
                  pButton icon="fa fa-close"
                  (click)="cancel()" label="Cancel"></button>
        </div>
        <div class="col-3">
          <button id="btnSubmit" type="button"
                  class="p-button-success alignRight"
                  pButton icon="fa fa-save"
                  [disabled]="isSubmitDisabled()"
                  (click)="submit()" label="Accept"></button>
        </div>
      </div>
    </p-footer>
  </p-panel>
</form>

<p-panel header="New Roles to be Assigned" *ngIf="newRoles.length !== 0">
  <p-table [value]="newRoles" columnResizeMode="fit">
      <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="LoginName" style="width: 200px">User <p-sortIcon field="LoginName"/></th>
            <th pColumn="roles">Roles</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
          <tr>
              <td>{{ user.LoginName }}</td>
              <div class="chip-container">
                <div *ngFor="let role of user.RolesArray">
                  <p-chip label="{{role.label}}" [removable]="false" />
                </div>
              </div>
          </tr>
      </ng-template>
  </p-table>
</p-panel>

<div style="height: 20px" *ngIf="removedRoles.length !== 0"></div>

<p-panel header="Roles to be Removed" *ngIf="removedRoles.length !== 0">
  <p-table [value]="removedRoles" dataKey="id" columnResizeMode="fit">
      <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="LoginName" style="width: 200px">User <p-sortIcon field="LoginName"/></th>
            <th pColumn="roles">Roles</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
          <tr>
              <td>{{ user.LoginName }}</td>
              <div class="chip-container">
                <div *ngFor="let role of user.RolesArray">
                  <p-chip label="{{role.label}}" [removable]="false" />
                </div>
              </div>
          </tr>
      </ng-template>
  </p-table>
</p-panel>

<p-footer>
  <div class="grid" style="margin-top: 10px">
    <div class="col-8"></div>
    <div class="col-2">
      <button id="btnCancel" type="button"
          class="p-button-warning alignRight"
          pButton icon="fa fa-close"
          (click)="cancel()" label="Cancel"></button>
    </div>
    <div class="col-2">
      <button id="btnConfirm" type="button"
          class="p-button-success alignRight"
          style="margin-left: 5px"
          pButton icon="fa fa-save"
          (click)="confirm()"
          label="Confirm"></button>
    </div>
  </div>
</p-footer>
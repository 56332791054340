<res-layout>
  <res-header>
    <res-logo appName="manageR" [showAppName]="true">
      <img res-logo-horizontal src="assets/rov-logo.png"/>
    </res-logo>

    <res-navigation>
      <res-navigation-item [active]="false" class="currentEnv">{{currentEnv}}</res-navigation-item>
      <res-navigation-group name="research area">
        <res-navigation-item (click)="navigateTo('create')">Create Research Area Data</res-navigation-item>
        <res-navigation-item (click)="navigateTo('home')">View Research Area Data</res-navigation-item>
        <res-navigation-item [style.display]="isROVAdmin || isROVSuperUser || isSupport ? 'block' : 'none'" (click)="navigateTo('userUtility')">RAD User Utility</res-navigation-item>
        <res-navigation-item [style.display]="isROVAdmin || isROVSuperUser ? 'block' : 'none'" (click)="navigateTo('UserAssociation')">User Association</res-navigation-item>
        <res-navigation-item [style.display]="isSupport ? 'block' : 'none'" (click)="navigateTo('UserRoles')">User Roles</res-navigation-item>
        <res-navigation-item [style.display]="isROVAdmin ? 'block' : 'none'" (click)="navigateTo('SaturnShipping')">Shipping</res-navigation-item>
        <res-navigation-item [style.display]="isROVAdmin ? 'block' : 'none'" (click)="cleanCache()">Clean RAD Cache</res-navigation-item>
      </res-navigation-group>
      <res-navigation-group [style.display]="isROVAdmin ? 'block' : 'none'" name="resource file">
        <res-navigation-item (click)="navigateTo('createFile')">Create File</res-navigation-item>
        <res-navigation-item (click)="navigateTo('uploadFile')">Upload File</res-navigation-item>
        <res-navigation-item (click)="navigateTo('scheduler')">Snapshot</res-navigation-item>
        <res-navigation-item (click)="navigateTo('historyFile')">Historical Files</res-navigation-item>
        <res-navigation-item (click)="annualResourceRefresh()">Annual Resource Refresh</res-navigation-item>
      </res-navigation-group>
      <res-navigation-group [style.display]="isROVAdmin ? 'block' : 'none'" name="category manager">
        <res-navigation-item (click)="navigateTo('categoryManager')">Manage Categories</res-navigation-item>
        <res-navigation-item (click)="navigateTo('AllocationEZProgram')">Allocation EZProgram</res-navigation-item>
        <res-navigation-item (click)="navigateTo('recentHistory')">{{historyCountString}}</res-navigation-item>
      </res-navigation-group>
    </res-navigation>

  </res-header>
  <router-outlet></router-outlet>
  <p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" appendTo="body"></p-confirmDialog>
  <p-toast position="top-right" [baseZIndex]="10001" [preventOpenDuplicates]="true"></p-toast>
  <div *ngIf="spinnerService.visibility | async" style="
      position: fixed;
      left: 0;
      top: 0;
      background-color: #AEAEAE;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 9999">
    <p-progressSpinner animationDuration="1s" [style]="{marginLeft:'50%', top:'50%'}"></p-progressSpinner>
  </div>

  <res-footer>
    <section res-footer-content>
    </section>

    <section res-footer-application-details>
      <div style="display: flex; justify-content: flex-end;">
        <div style="opacity: 1"></div>

        <div>
          <div>UI Version: {{versionNumber}}</div>
          <div>API Version: {{apiVersionNumber}}</div>
        </div>
      </div>
    </section>
  </res-footer>
</res-layout>
